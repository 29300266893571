var render = function () {
  var _vm$item, _vm$item$users, _vm$item$currency_cod, _vm$item$currency_cod2, _vm$item$currency_cod3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('h1', {
    staticClass: "text-primary"
  }, [_vm._v("Detail Order")])]), _c('br'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v(" Detail Transaction ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Order ID")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.item.id) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Email")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: '/customers/detail/' + ((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : (_vm$item$users = _vm$item.users) === null || _vm$item$users === void 0 ? void 0 : _vm$item$users.email)
      },
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.users ? _vm.item.users.email : '-') + " ")])], 1)]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Transaction Time")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.item.created)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Modified")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.item.modified)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Order Number")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.item.order_number) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Remote Order Number")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.item.remote_order_number ? _vm.item.remote_order_number : '-') + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Status Order")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('div', {
    staticClass: "float-left",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('paymentview', {
    attrs: {
      "payment": _vm.item.order_status
    }
  })], 1)])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Renewal Type")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm.item.is_renewal ? _c('span', {
    staticClass: "badge badge-pill badge-primary"
  }, [_vm._v(" Renewal ")]) : _c('span', {
    staticClass: "badge badge-pill badge-danger"
  }, [_vm._v(" Non-Renewal ")])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Total Amount")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$item$currency_cod = _vm.item.currency_code) === null || _vm$item$currency_cod === void 0 ? void 0 : _vm$item$currency_cod.toUpperCase()) + " " + _vm._s(_vm.formatPrice(_vm.item.total_amount, _vm.item.currency_code)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Discount")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$item$currency_cod2 = _vm.item.currency_code) === null || _vm$item$currency_cod2 === void 0 ? void 0 : _vm$item$currency_cod2.toUpperCase()) + " " + _vm._s(_vm.getDiscountPrice(_vm.item.total_amount, _vm.item.final_amount, _vm.item.currency_code)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Final Amount")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$item$currency_cod3 = _vm.item.currency_code) === null || _vm$item$currency_cod3 === void 0 ? void 0 : _vm$item$currency_cod3.toUpperCase()) + " " + _vm._s(_vm.formatPrice(_vm.item.final_amount, _vm.item.currency_code)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Payment Type")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.item.payment.name) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Platform Type")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('div', {
    staticClass: "float-left"
  }, [_c('Platform', {
    attrs: {
      "platform": _vm.item.platform_id
    }
  })], 1)])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Client ID")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.clientID(_vm.item.client_id)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Tier Code")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.item.tier_code ? _vm.item.tier_code : '-') + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Status")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm.item.is_active ? _c('div', [_c('span', {
    staticClass: "badge badge-pill badge-primary"
  }, [_c('i', {
    staticClass: "fa fa-check"
  }), _vm._v(" active")])]) : _c('div', [_c('span', {
    staticClass: "badge badge-pill badge-danger"
  }, [_c('i', {
    staticClass: "fa fa-close"
  }), _vm._v(" inactive")])])])])])])])]), _c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v("Detail Orderline")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-table', {
    attrs: {
      "id": "table-transition",
      "show-empty": "",
      "fields": _vm.headOrderline,
      "hover": "",
      "responsive": "xl",
      "items": _vm.item.orderlines,
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(id)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.id) + " ")];
      }
    }, {
      key: "cell(item_name)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.name) + " ")];
      }
    }, {
      key: "cell(brand_name)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.brand_name) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.quantity) + " ")];
      }
    }, {
      key: "cell(price)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.currency_code) + " " + _vm._s(_vm.formatPrice(row.item.price, row.item.currency_code)) + " ")];
      }
    }, {
      key: "cell(discount)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.currency_code) + " " + _vm._s(_vm.getDiscountPricePerItem(row.item.price, row.item.final_price, row.item.currency_code, row.item.quantity)) + " ")];
      }
    }, {
      key: "cell(final_price)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.currency_code) + " " + _vm._s(_vm.formatPrice(row.item.final_price, row.item.currency_code)) + " ")];
      }
    }])
  })], 1)]), _c('br'), _c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v("Detail Payment")])]), _c('div', {
    staticClass: "card-body"
  }, [_vm.detailPayment ? _c('div', [_c('detailPaymentVue', {
    attrs: {
      "orderdetail": _vm.detailPayment,
      "updatePaymentType": _vm.onUpdatePaymentType
    }
  })], 1) : _vm._e(), _vm.item.platform_id === 1 ? _c('div', {
    staticClass: "float-right mb-3"
  }, [[20001, 50004, 20002, 10001].includes(_vm.item.order_status) ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.popupAppleID
    }
  }, [_vm._v(" Insert Apple Order ID ")]) : _vm._e()]) : _vm._e(), _c('br'), this.item.paymentgateway_id == _vm.applePay && _vm.detailPayment.paymentappleiaps ? _c('div', [_c('Itunes', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.googlePay && _vm.detailPayment.paymentinappbillings ? _c('div', [_c('Google', {
    attrs: {
      "orderdetail": _vm.detailPayment,
      "submitToken": _vm.onSubmitToken,
      "submitGPA": _vm.onSubmitGPA
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.linkAjaPay && (_vm.detailPayment.paymentva || _vm.detailPayment.paymentdetail) ? _c('div', [_c('LinkAja', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), _vm.paymentVA.includes(parseInt(this.item.paymentgateway_id)) && (_vm.detailPayment.paymentva || _vm.detailPayment.paymentdetail) ? _c('div', [_c('VirtualAccount', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.gopay && _vm.detailPayment.paymentgopay ? _c('div', [_c('GoPay', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.cc && _vm.detailPayment.paymentcc ? _c('div', [_c('CreditCard', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.free && _vm.detailPayment.orderlinediscount ? _c('div', [_c('FreePurchase', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), _vm.eTwoPayCIMB.includes(parseInt(this.item.paymentgateway_id)) && _vm.detailPayment.paymentdetail ? _c('div', [_c('eTwoPayGroup', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.scoopWallet && _vm.detailPayment ? _c('div', [_c('ScoopWallet', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e()])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }