var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_vm.platform in _vm.platformIcons ? _c('i', {
    class: _vm.platformIcons[_vm.platform],
    attrs: {
      "aria-hidden": "true"
    }
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }