var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard"
  }, [_c('Invoice'), _c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  })], 1), _vm.items && _vm.items.length < 1 ? _c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('b-skeleton-wrapper', {
    attrs: {
      "loading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c('b-card', [_c('b-skeleton', {
          attrs: {
            "width": "85%"
          }
        }), _c('b-skeleton', {
          attrs: {
            "width": "55%"
          }
        }), _c('b-skeleton', {
          attrs: {
            "width": "70%"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 2022486804)
  })], 1)]) : _c('div', {
    staticClass: "row mt-2"
  }, _vm._l(_vm.latesRevenue(_vm.items), function (revenue, no) {
    return _c('div', {
      key: no,
      staticClass: "col-md-4"
    }, [_c('b-card', {
      key: no,
      staticClass: "shadow-lg",
      attrs: {
        "no-body": true
      }
    }, [_c('b-card-body', {
      staticClass: "p-0 clearfix"
    }, [_c('em', {
      staticClass: "fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left"
    }), !_vm.isLoading ? _c('div', {
      staticClass: "h5 text-uppercase mb-0 pt-3"
    }, [revenue.offer_type == 'buffet' ? _c('em', {
      staticClass: "fa fa-diamond"
    }) : revenue.offer_type == 'Single' ? _c('em', {
      staticClass: "fa fa-book",
      attrs: {
        "aria-hidden": "true"
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(revenue.offer_type) + " ")]) : _c('div', {
      staticClass: "h6 text-info mb-0 pt-3"
    }, [_c('b-spinner', {
      attrs: {
        "type": "grow",
        "label": "Loading..."
      }
    })], 1), _c('div', {
      staticClass: "text-muted text-uppercase font-md"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(revenue.total, "IDR")) + " ")]), _c('div', {
      staticClass: "text-muted font-xs"
    }, [_vm._v(" " + _vm._s(_vm.formatDate(revenue.disbursement_period)) + " ")])])], 1)], 1);
  }), 0), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row mt-2 mb-1"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('h4', {
    staticClass: "h4 text-left"
  }, [_vm._v("Sales")]), _vm.isLoadingPopular ? _c('b-spinner', {
    staticClass: "align-middle",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.loadChart ? 'Loading ...' : '') + " 'Loading ...' ")]) : _vm._e()], 1), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.showCountMonth,
      expression: "showCountMonth"
    }],
    staticClass: "form-control-sm",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.showCountMonth = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "3"
    }
  }, [_vm._v("3 Month")]), _c('option', {
    attrs: {
      "value": "6"
    }
  }, [_vm._v("6 Month")])])]), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('i', {
    staticClass: "fa fa-line-chart btn btn-sm",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function ($event) {
        return _vm.actionChangeChartType('line');
      }
    }
  }), _c('i', {
    staticClass: "fa fa-bar-chart btn btn-sm",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function ($event) {
        return _vm.actionChangeChartType('bar');
      }
    }
  }), _c('i', {
    staticClass: "fa fa-area-chart btn btn-sm",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function ($event) {
        return _vm.actionChangeChartType('area');
      }
    }
  })]), _c('div', {
    staticClass: "col-md-3"
  })]), _c('div', {
    staticClass: "row mb-2 pb-1"
  }, [_c('div', {
    staticClass: "col-md-9 shadow-sm p-1"
  }, [_c('RevenueLine', {
    attrs: {
      "type": _vm.typeChart,
      "height": 400,
      "options": _vm.options,
      "series": _vm.series
    },
    on: {
      "export": _vm.handleExport
    }
  })], 1), _c('div', {
    staticClass: "col-md-3 pt-1 pb-1"
  }, [_vm._v(" " + _vm._s(_vm.loadChart ? 'Loading ...' : '') + " "), _vm.summary ? _c('div', {
    staticClass: "card p-2 rounded"
  }, [_c('label', {
    staticClass: "form-control-label text-black"
  }, [_vm._v("Summary ")]), _c('div', {
    staticClass: "card-body shadow-lg rounded"
  }, [_c('p', [_vm._v("Total : " + _vm._s(_vm.formatPrice(_vm.summary.count, "IDR")))]), _c('p', [_vm._v("Average : " + _vm._s(_vm.formatPrice(_vm.summary.average, "IDR")))]), _c('p', [_vm._v("Median : " + _vm._s(_vm.formatPrice(_vm.summary.median, "IDR")))]), _c('p', [_vm._v("Max : " + _vm._s(_vm.formatPrice(_vm.summary.max, "IDR")))]), _c('p', [_vm._v("Min : " + _vm._s(_vm.formatPrice(_vm.summary.min, "IDR")))])])]) : _vm._e()])])]), _c('div', {
    staticClass: "col-md-12 shadow-lg rounded mb-4"
  }, [_c('h4', {
    staticClass: "h4 text-left p-2"
  }, [_vm._v(" Top 5 Premium Reading ")]), _c('div', {
    staticClass: "row"
  }, [_vm.isLoadingPopular ? _c('b-spinner', {
    staticClass: "align-middle"
  }, [_vm._v(" " + _vm._s(_vm.isLoadingPopular ? 'Loading ...' : '') + " ")]) : _vm._e()], 1), !_vm.isLoadingPopular ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.popular, function (item, no) {
    return _c('div', {
      key: item.id,
      staticClass: "col-sm-4"
    }, [_c('b-card', {
      staticClass: "overflow-hidden border-0",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "sm": "4"
      }
    }, [_c('b-card-img', {
      staticClass: "rounded-0 cover",
      attrs: {
        "src": _vm.basePath + _vm.removeBucket(item.thumb_image_normal),
        "alt": "Image"
      }
    })], 1), _c('b-col', {
      attrs: {
        "sm": "8"
      }
    }, [_c('b-card-body', [_vm._v(" #" + _vm._s(no + 1) + " "), _c('strong', [_vm._v(_vm._s(item.item_name.substr(0, 50)) + " ")]), _c('b-card-text', [_c('br'), _vm._v(" Read by " + _vm._s(item.people) + " User "), _c('br'), _vm._v(" of " + _vm._s(_vm.formatSeconToDays(item.duration)) + " ")])], 1)], 1)], 1)], 1)], 1);
  }), 0) : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('b-skeleton-img')], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('b-skeleton-img')], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('b-skeleton-img')], 1)])]), _c('div', {
    staticClass: "col-md-12 shadow-lg rounded mb-4"
  }, [_c('h4', {
    staticClass: "h4 text-left p-2"
  }, [_vm._v(" Content Aggregate ")]), _c('div', {
    staticClass: "row"
  }, [_vm.content.length > 0 ? _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-card', [_c('ChartContent', {
    attrs: {
      "title": 'Content',
      "series": _vm.seriesContent,
      "options": _vm.optionsContent
    }
  })], 1)], 1) : _vm._e(), _vm.content ? _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "sm",
      "show-empty": "",
      "striped": "",
      "busy": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "items": _vm.content
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(count)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPriceBasic(row.item.count)) + " ")];
      }
    }], null, false, 3540317890)
  })], 1)], 1) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }