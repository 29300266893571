var render = function () {
  var _vm$ebooks$file_size, _vm$ItemSoaxPremium, _vm$ItemSoaxPremium2, _vm$ItemSoaxPremium3, _vm$ItemSoaxPremium4, _vm$ItemSoaxPremium5, _vm$ItemSoaxPremium6, _vm$ItemSoaxPremium7, _vm$ItemSoaxPremium8, _vm$ItemSoax, _vm$ItemSoax2, _vm$ItemSoax3, _vm$ItemSoax4, _vm$ItemSoax5, _vm$ItemSoax6, _vm$ebooks, _vm$ebooks$single, _vm$ebooks2, _vm$ebooks2$buffets;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5"
  }, [_c('label', [_c('router-link', {
    staticClass: "text-capitalize text-gray-900 font-semibold",
    attrs: {
      "to": {
        name: _vm.listPath[_vm.ebooks.item_type]
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.item_type))]), _vm._v(" "), _c('i', {
    staticClass: "fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"
  }), _vm._v(" "), _c('span', {
    staticClass: "text-gray-500 font-normal capitalize"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.name))])], 1), _c('h4', {
    staticClass: "text-xl capitalize"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.name))])]), _c('div', {
    staticClass: "bg-white w-full rounded-lg mt-3 mb-3 px-4 py-4"
  }, [_c('div', {
    staticClass: "flex justify-between items-end"
  }, [_c('div', [_c('h4', {
    staticClass: "text-xl font-bold capitalize"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.item_type) + " Details")])]), _c('div', {
    staticClass: "pt-4"
  }, [_c('button', {
    staticClass: "bg-blue-500 p-2 px-2 text-white rounded-lg flex justify-start items-center",
    on: {
      "click": _vm.goToEdit
    }
  }, [_c('svg', {
    staticClass: "w-6 h-6 text-white dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "currentColor",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z",
      "clip-rule": "evenodd"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z",
      "clip-rule": "evenodd"
    }
  })]), _vm._v(" Edit Details ")])])]), _c('div', {
    staticClass: "flex justify-start mt-2"
  }, [_c('div', {
    staticClass: "w-1/2 flex-col"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Name")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.name) + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Edition Code")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.edition_code) + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Vendor")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.brands ? _vm.ebooks.brands.vendor.name : '-') + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Authors")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.listObjectToString(_vm.ebooks.authors, 'name') || '-') + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Brands")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_c('label', [_vm._v("#" + _vm._s(_vm.ebooks.brand_id))]), _vm._v(" " + _vm._s(_vm.ebooks.brands ? _vm.ebooks.brands.name : '-') + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Categories")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.listObjectToString(_vm.ebooks.categories, 'name') || '-') + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Parental Control")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.parentalControl) + " (" + _vm._s(_vm.descParentalControl) + ") ")]), !_vm.isProfileVendor ? _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Category AX")]) : _vm._e(), !_vm.isProfileVendor ? _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.category_ax ? _vm.ebooks.category_ax.category_ax : '-') + " ")]) : _vm._e(), _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Current File")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.fileSizeFormat((_vm$ebooks$file_size = _vm.ebooks.file_size) !== null && _vm$ebooks$file_size !== void 0 ? _vm$ebooks$file_size : 0)) + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Content Type")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal uppercase"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.content_type) + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Link Preview")]), _vm.ebooks.item_status == 'ready for consume' ? _c('div', [_c('a', {
    attrs: {
      "href": _vm.urlCatalog(_vm.ebooks),
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(_vm._s(_vm.urlCatalog(_vm.ebooks)))])]) : _c('div', [_c('span', [_vm._v(" Link preview not yet showing up, because not ready for consume ")])]), _c('label', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Description")]), _c('div', {
    class: _vm.readMoreToggle ? 'max-w-max' : 'h-20 truncate'
  }, [_vm._v(_vm._s(_vm.ebooks.description) + " ")]), _c('div', {
    staticClass: "flex justify-end text-right"
  }, [_c('span', {
    staticClass: "cursor-pointer",
    on: {
      "click": _vm.readMore
    }
  }, [_vm._v(_vm._s(_vm.readMoreToggle ? 'Read Less' : 'Read More') + " "), _c('i', {
    class: _vm.readMoreToggle ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'
  })])])]), _c('div', {
    staticClass: "w-1/2 flex-col"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Release Date")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.datetime24H(_vm.ebooks.release_date)) + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-0"
  }, [_vm._v("Release Schedule")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.datetime24H(_vm.ebooks.release_schedule)) + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Status")]), _vm.ebooks.item_status == _vm.itemStatus.Unpublished ? _c('p', {
    staticClass: "bg-red-100 rounded-lg w-max text-xs text-red-800 p-1 px-2"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.item_status) + " ")]) : _vm.ebooks.item_status == _vm.itemStatus.Published ? _c('p', {
    staticClass: "bg-blue-100 p-1 px-2 w-max rounded-lg text-xs text-blue-800"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.item_status) + " ")]) : _vm.ebooks.item_status == _vm.itemStatus.Uploaded ? _c('p', {
    staticClass: "bg-yellow-100 w-max rounded-lg text-xs text-yellow-800 p-1 px-2"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.item_status) + " ")]) : _vm.ebooks.item_status == _vm.itemStatus.UnpublishedMcgrawhill ? _c('p', {
    staticClass: "bg-gray-100 w-max rounded-lg text-xs text-gray-800 p-1 px-2"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.item_status) + " ")]) : _c('p', {
    staticClass: "bg-green-100 w-max p-1 px-2 rounded-lg text-xs text-green-800"
  }, [_vm._v(" " + _vm._s(_vm.ebooks.item_status) + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Active")]), _c('p', {
    staticClass: "text-sm w-max p-0 px-2 rounded-lg font-normal",
    class: _vm.ebooks.is_active ? 'bg-green-100 text-green-800' : 'text-gray-500 bg-gray-100'
  }, [_vm._v(" " + _vm._s(_vm.ebooks.is_active ? 'Yes' : 'No') + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Internal Content")]), _c('p', {
    staticClass: "text-sm w-max p-0 px-2 rounded-lg font-normal",
    class: _vm.ebooks.is_internal_content ? 'bg-green-100 text-green-800' : 'text-gray-500 bg-gray-100'
  }, [_vm._v(" " + _vm._s(_vm.ebooks.is_internal_content ? 'Yes' : 'No') + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Category Retail")]), _c('p', {
    staticClass: "text-sm w-max p-0 px-2 rounded-lg font-normal",
    class: _vm.ebooks.category_ax && _vm.ebooks.category_ax.is_retail ? 'bg-green-100 text-green-800' : 'text-gray-500 bg-gray-100'
  }, [_vm._v(" " + _vm._s(_vm.ebooks.category_ax ? _vm.ebooks.category_ax.is_retail ? 'Yes' : 'No' : '') + " ")])])])]), _c('div', {
    staticClass: "flex justify-start bg-white p-4 rounded-lg"
  }, [_c('div', {
    staticClass: "block border-0 w-full"
  }, [_c('h4', {
    staticClass: "text-xl font-bold capitalize"
  }, [_vm._v(" Cover")]), _c('EditCover'), _vm.isShowPreview() ? _c('div', {
    staticClass: "mt-3 mb-2 w-full pr-2 border-t pt-3"
  }, [_c('h4', {
    staticClass: "text-xl font-bold capitalize mb-2"
  }, [_vm._v(" Preview")]), _c('router-link', {
    staticClass: "text-left text-white block mb-4 border-b pb-4",
    attrs: {
      "to": {
        name: _vm.EditPreviewItemPath.name
      }
    }
  }, [_c('button', {
    staticClass: "bg-gray-700 rounded-lg p-2.5"
  }, [_vm._v(" Upload Preview ")])]), _c('div', {
    staticClass: "grid gap-x-3 gap-y-4 grid-cols-8",
    attrs: {
      "id": "row-preview"
    }
  }, _vm._l(_vm.ebooks.preview_page, function (page) {
    return _c('div', {
      key: page
    }, [_c('img', {
      staticClass: "w-full img-sm shadow-md rounded",
      attrs: {
        "src": _vm.srcPreview(page) + '?' + _vm.now,
        "alt": "Image"
      },
      on: {
        "click": function ($event) {
          return _vm.selectedPreview(page);
        }
      }
    })]);
  }), 0)], 1) : _vm._e()], 1)]), !_vm.isProfileVendor ? _c('div', {
    staticClass: "w-full mb-3 mt-3 rounded-lg shadow-sm",
    attrs: {
      "id": "item-ax "
    }
  }, [_c('div', {
    staticClass: "grid gap-4 grid-cols-2 sm"
  }, [_c('div', {
    staticClass: "bg-white rounded-lg px-3 py-4",
    attrs: {
      "id": "item-ax"
    }
  }, [_c('div', {
    staticClass: "w-full flex justify-between items-center"
  }, [_c('h4', {
    staticClass: "text-xl font-bold capitalize mb-0"
  }, [_vm._v(" Premium AX")]), _c('button', {
    staticClass: "flex bg-blue-700 p-2 rounded-lg text-white",
    on: {
      "click": function ($event) {
        return _vm.$refs.modal_premium_ax.show();
      }
    }
  }, [_vm._v("Update Premium AX")])]), _c('div', {
    staticClass: "flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Premium Ax")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s((_vm$ItemSoaxPremium = _vm.ItemSoaxPremium) !== null && _vm$ItemSoaxPremium !== void 0 && _vm$ItemSoaxPremium.premium_idax ? (_vm$ItemSoaxPremium2 = _vm.ItemSoaxPremium) === null || _vm$ItemSoaxPremium2 === void 0 ? void 0 : _vm$ItemSoaxPremium2.premium_idax : "Don't have premium ax yet") + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Base Price")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s((_vm$ItemSoaxPremium3 = _vm.ItemSoaxPremium) !== null && _vm$ItemSoaxPremium3 !== void 0 && _vm$ItemSoaxPremium3.base_price ? (_vm$ItemSoaxPremium4 = _vm.ItemSoaxPremium) === null || _vm$ItemSoaxPremium4 === void 0 ? void 0 : _vm$ItemSoaxPremium4.base_price : "Don't have base price yet") + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Vendor")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s((_vm$ItemSoaxPremium5 = _vm.ItemSoaxPremium) !== null && _vm$ItemSoaxPremium5 !== void 0 && _vm$ItemSoaxPremium5.vendor ? (_vm$ItemSoaxPremium6 = _vm.ItemSoaxPremium) === null || _vm$ItemSoaxPremium6 === void 0 ? void 0 : _vm$ItemSoaxPremium6.vendor : "Don't have have vendor yet") + " ")])]), _c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Vendor ID AX")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s((_vm$ItemSoaxPremium7 = _vm.ItemSoaxPremium) !== null && _vm$ItemSoaxPremium7 !== void 0 && _vm$ItemSoaxPremium7.vendor_id_ax ? (_vm$ItemSoaxPremium8 = _vm.ItemSoaxPremium) === null || _vm$ItemSoaxPremium8 === void 0 ? void 0 : _vm$ItemSoaxPremium8.vendor_id_ax : "Don't have vendor ax yet") + " ")])]), _c('div', {
    staticClass: "bg-white rounded-lg px-3 py-4"
  }, [_c('div', {
    staticClass: "w-full flex justify-between items-center"
  }, [_c('h4', {
    staticClass: "text-xl font-bold capitalize mb-0"
  }, [_vm._v(" Single AX")]), _c('button', {
    staticClass: "flex bg-blue-700 p-2 rounded-lg text-white",
    on: {
      "click": function ($event) {
        return _vm.$refs.modal_single_ax.show();
      }
    }
  }, [_vm._v("Update Single AX")])]), _c('div', {
    staticClass: "flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Single Ax")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s(_vm.ItemSoax ? _vm.ItemSoax.single_idax : "Don't have single ax yet") + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Base Price")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s((_vm$ItemSoax = _vm.ItemSoax) !== null && _vm$ItemSoax !== void 0 && _vm$ItemSoax.base_price ? (_vm$ItemSoax2 = _vm.ItemSoax) === null || _vm$ItemSoax2 === void 0 ? void 0 : _vm$ItemSoax2.base_price : "Don't have base price yet") + " ")]), _c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Vendor")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s((_vm$ItemSoax3 = _vm.ItemSoax) !== null && _vm$ItemSoax3 !== void 0 && _vm$ItemSoax3.vendor ? (_vm$ItemSoax4 = _vm.ItemSoax) === null || _vm$ItemSoax4 === void 0 ? void 0 : _vm$ItemSoax4.vendor : "Don't have vendor yet") + " ")])]), _c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Vendor Ax")]), _c('p', {
    staticClass: "text-base text-gray-500 font-normal"
  }, [_vm._v(" " + _vm._s((_vm$ItemSoax5 = _vm.ItemSoax) !== null && _vm$ItemSoax5 !== void 0 && _vm$ItemSoax5.vendor_id_ax ? (_vm$ItemSoax6 = _vm.ItemSoax) === null || _vm$ItemSoax6 === void 0 ? void 0 : _vm$ItemSoax6.vendor_id_ax : "Don't have vendor ax yet") + " ")])])])]) : _vm._e(), _vm.ebooks.item_type == _vm.item_types_audio ? _c('b-card', {
    attrs: {
      "id": "chapter-audio"
    }
  }, [_c('h4', {
    staticClass: "card-title text-center h3 p-1"
  }, [_vm._v(" Chapter [Audio Only] ")]), _c('b-table', {
    attrs: {
      "fields": _vm.fieldsAudio,
      "hover": "",
      "responsive": "sm",
      "striped": true,
      "items": _vm.audioItem,
      "show-empty": true
    },
    scopedSlots: _vm._u([{
      key: "cell(is_active)",
      fn: function (row) {
        return [_c('div', [row.item.is_active ? _c('span', {
          staticClass: "badge"
        }, [_vm._v("✅")]) : _c('span', {
          staticClass: "badge",
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" ✕ ")])])];
      }
    }, {
      key: "cell(file_size)",
      fn: function (row) {
        return [_c('div', [_vm._v(" " + _vm._s(row.item.file_size) + " ")])];
      }
    }], null, false, 2797678419)
  }), _vm.ebooks.item_status == 'ready for upload' ? _c('b-col', [_c('b-button', {
    staticClass: "font-weight-bold",
    attrs: {
      "variant": "primary",
      "md": "6",
      "lg": "2"
    },
    on: {
      "click": _vm.actionEditChapter
    }
  }, [_vm._v("Edit Chapter")])], 1) : _c('b-col', [_c('b-button', {
    staticClass: "font-weight-bold",
    attrs: {
      "variant": "primary",
      "md": "6",
      "lg": "2"
    },
    on: {
      "click": _vm.actionEditChapter
    }
  }, [_vm._v("View Chapter")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "mt-3 mb-3 px-4 pb-4 rounded-md bg-white",
    attrs: {
      "id": "offer-single"
    }
  }, [_c('h4', {
    staticClass: "card-title text-left text-xl px-2 pt-4 font-semibold"
  }, [_vm._v(" Offers Single")]), _c('div', {
    staticClass: "rounded-lg shadow-sm"
  }, [_c('t-table', {
    staticClass: "shadow-none border border-gray-50 bg-white",
    attrs: {
      "headers": _vm.fields,
      "data": _vm.ebooks.single
    },
    scopedSlots: _vm._u([!((_vm$ebooks = _vm.ebooks) !== null && _vm$ebooks !== void 0 && (_vm$ebooks$single = _vm$ebooks.single) !== null && _vm$ebooks$single !== void 0 && _vm$ebooks$single.length) ? {
      key: "tbody",
      fn: function (props) {
        return [_c('tbody', {
          class: props.tbodyClass
        }, [_c('tr', {
          class: [props.trClass, 'text-center']
        }, [_c('td', {
          class: props.tdClass,
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v(" There are no records to show ")])])])];
      }
    } : null, {
      key: "thead",
      fn: function (props) {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left",
          class: props.thClass
        }, [_vm._v(" Name ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Offer Code ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Price IDR ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Price USD ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Is Free ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Offer Status ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Active ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Created ")])])])];
      }
    }, {
      key: "row",
      fn: function (props) {
        return [_c('tr', {
          staticClass: "border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3"
        }, [_c('td', {
          staticClass: "max-w-xs text-left pl-2",
          class: props.tdClass
        }, [_c('router-link', {
          staticClass: "text-left text-blue-700",
          attrs: {
            "to": {
              name: _vm.SingleEditPath.name,
              params: {
                id: props.row.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.name) + " ")])], 1), _c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [_vm._v(" " + _vm._s(props.row.offer_code) + " ")]), _c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [_vm._v(" " + _vm._s(props.row.price_idr) + " ")]), _c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [_vm._v(" " + _vm._s(props.row.price_usd) + " ")]), _c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [_c('span', {
          class: [{
            'bg-green-100 text-green-800': props.row.is_free,
            'bg-gray-200 text-gray-800': !props.row.is_free
          }, 'text-left rounded-lg text-xs p-1 px-2 ml-2']
        }, [_vm._v(" " + _vm._s(_vm.formatBoolean(props.row.is_free)) + " ")])]), _c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [props.row.offer_status == '8' ? _c('span', {
          staticClass: "bg-red-100 rounded-lg text-xs text-red-800 p-1"
        }, [_vm._v(" Not For Sale")]) : _c('span', {
          staticClass: "bg-blue-100 p-1 rounded-lg text-xs text-blue-800"
        }, [_vm._v(" Ready For Sale ")])]), _c('td', {
          class: props.tdClass
        }, [_c('span', {
          class: [{
            'bg-green-100 text-green-800': props.row.is_active,
            'bg-gray-200 text-gray-800': !props.row.is_active
          }, 'text-left rounded-lg text-xs p-1 px-2 ml-2']
        }, [_vm._v(" " + _vm._s(_vm.formatBoolean(props.row.is_active)) + " ")])]), _c('td', {
          class: props.tdClass
        }, [_vm._v(" " + _vm._s(_vm.formatterDate(props.row.created)) + " ")])])];
      }
    }], null, true)
  })], 1)]), _c('div', {
    staticClass: "mt-2 mb-4 px-4 pb-4 rounded-md bg-white",
    attrs: {
      "id": "offer-buffets"
    }
  }, [_c('h4', {
    staticClass: "card-title text-left text-xl px-2 pt-4 font-semibold"
  }, [_vm._v(" Offers Buffets")]), _c('div', {
    staticClass: "rounded-lg shadow-sm"
  }, [_c('t-table', {
    staticClass: "shadow-none border border-gray-50 bg-white",
    attrs: {
      "headers": _vm.fields,
      "data": _vm.ebooks.buffets
    },
    scopedSlots: _vm._u([!((_vm$ebooks2 = _vm.ebooks) !== null && _vm$ebooks2 !== void 0 && (_vm$ebooks2$buffets = _vm$ebooks2.buffets) !== null && _vm$ebooks2$buffets !== void 0 && _vm$ebooks2$buffets.length) ? {
      key: "tbody",
      fn: function (props) {
        return [_c('tbody', {
          class: props.tbodyClass
        }, [_c('tr', {
          class: [props.trClass, 'text-center']
        }, [_c('td', {
          class: props.tdClass,
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v(" There are no records to show ")])])])];
      }
    } : null, {
      key: "thead",
      fn: function (props) {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left",
          class: props.thClass
        }, [_vm._v(" Name ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Offer Code ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Price IDR ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Price USD ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Is Free ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Offer Status ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Active ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Created ")])])])];
      }
    }, {
      key: "row",
      fn: function (props) {
        return [_c('tr', {
          staticClass: "border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3"
        }, [_c('td', {
          staticClass: "max-w-xs text-left pl-2",
          class: props.tdClass
        }, [_c('router-link', {
          staticClass: "text-left text-blue-700",
          attrs: {
            "to": {
              name: _vm.PremiumEditPath.name,
              params: {
                id: props.row.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.name) + " ")])], 1), _c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [_vm._v(" " + _vm._s(props.row.offer_code) + " ")]), _c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [_vm._v(" " + _vm._s(props.row.price_idr) + " ")]), _c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [_vm._v(" " + _vm._s(props.row.price_usd) + " ")]), _c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [_c('span', {
          class: [{
            'bg-green-100 text-green-800': props.row.is_free,
            'bg-gray-200 text-gray-800': !props.row.is_free
          }, 'text-left rounded-lg text-xs p-1 px-2 ml-2']
        }, [_vm._v(" " + _vm._s(_vm.formatBoolean(props.row.is_free)) + " ")])]), _c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [props.row.offer_status == '8' ? _c('span', {
          staticClass: "bg-red-100 rounded-lg text-xs text-red-800 p-1"
        }, [_vm._v(" Not For Sale")]) : _c('span', {
          staticClass: "bg-blue-100 p-1 rounded-lg text-xs text-blue-800"
        }, [_vm._v(" Ready For Sale ")])]), _c('td', {
          class: props.tdClass
        }, [_c('span', {
          class: [{
            'bg-green-100 text-green-800': props.row.is_active,
            'bg-gray-200 text-gray-800': !props.row.is_active
          }, 'text-left rounded-lg text-xs p-1 px-2 ml-2']
        }, [_vm._v(" " + _vm._s(_vm.formatBoolean(props.row.is_active)) + " ")])]), _c('td', {
          class: props.tdClass
        }, [_vm._v(" " + _vm._s(_vm.formatterDate(props.row.created)) + " ")])])];
      }
    }], null, true)
  })], 1)]), _c('t-modal', {
    ref: "modal_single_ax"
  }, [_c('label', {
    staticClass: "text-base mb-3"
  }, [_vm._v("Update Single Ax")]), _c('div', {
    staticClass: "flex-col w-full"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('label', {
    staticClass: "block text-sm font-medium text-gray-700",
    attrs: {
      "for": "singleAx"
    }
  }, [_vm._v("Single Ax")]), _c('t-input', {
    staticClass: "mt-1 block w-full",
    attrs: {
      "id": "premiumAx",
      "type": "number",
      "placeholder": "Enter Single Ax",
      "min": 0,
      "required": ""
    },
    model: {
      value: _vm.form.singleAx,
      callback: function ($$v) {
        _vm.$set(_vm.form, "singleAx", $$v);
      },
      expression: "form.singleAx"
    }
  })], 1), _c('div', {
    staticClass: "mb-2"
  }, [_c('label', {
    staticClass: "block text-sm font-medium text-gray-700",
    attrs: {
      "for": "basePrice"
    }
  }, [_vm._v("Base Price")]), _c('t-input', {
    staticClass: "mt-1 block w-full",
    attrs: {
      "id": "basePrice",
      "type": "number",
      "placeholder": "Enter Base Price",
      "min": 0,
      "required": ""
    },
    model: {
      value: _vm.form.basePriceSingle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "basePriceSingle", $$v);
      },
      expression: "form.basePriceSingle"
    }
  })], 1)]), _c('div', {
    staticClass: "flex-col w-full"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('label', {
    staticClass: "block text-sm font-medium text-gray-700",
    attrs: {
      "for": "vendor"
    }
  }, [_vm._v("Vendor")]), _c('multiselect', {
    staticClass: "mt-1 block w-full",
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Type Vendor",
      "open-direction": "bottom",
      "options": _vm.vendorSearchOptions,
      "searchable": true,
      "loading": _vm.isVendorSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": false,
      "required": ""
    },
    on: {
      "search-change": _vm.vendorFind,
      "input": _vm.getVendorData
    },
    model: {
      value: _vm.form.vendorSingle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vendorSingle", $$v);
      },
      expression: "form.vendorSingle"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "block text-sm font-medium text-gray-700",
    attrs: {
      "for": "vendorAx"
    }
  }, [_vm._v("Vendor ID AX")]), _c('t-input', {
    staticClass: "mt-1 block w-full",
    attrs: {
      "id": "vendorAx",
      "type": "text",
      "placeholder": "Enter Vendor ax",
      "required": ""
    },
    model: {
      value: _vm.form.vendorAxSingle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vendorAxSingle", $$v);
      },
      expression: "form.vendorAxSingle"
    }
  })], 1)]), _c('div', {
    staticClass: "flex justify-end mt-4"
  }, [_c('button', {
    staticClass: "bg-blue-700 text-white p-1 px-2 rounded-lg m-1",
    on: {
      "click": function ($event) {
        return _vm.actionUpdateSingle();
      }
    }
  }, [_vm._v("Update")])])]), _c('t-modal', {
    ref: "confirmationModal"
  }, [_c('div', {
    staticClass: "text-sm flex-col text-center"
  }, [_c('label', {
    staticClass: "text-center font-semibold"
  }, [_vm._v("Are You Sure?")]), _c('p', {
    staticClass: "text-left text-gray-500"
  }, [_vm._v("You want Update ")])]), _c('div', {
    staticClass: "flex justify-center"
  }, [_c('button', {
    staticClass: "bg-blue-500 text-white p-1 px-2 rounded-lg mr-1 text-sm",
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "bg-white border text-gray-500 p-1 px-2 rounded-lg ml-1 text-sm",
    on: {
      "click": _vm.confirmUpdate
    }
  }, [_vm._v("Yes")])])]), _c('t-modal', {
    ref: "confirmationModalSingle"
  }, [_c('div', {
    staticClass: "text-sm flex-col text-center"
  }, [_c('label', {
    staticClass: "text-center font-semibold"
  }, [_vm._v("Are You Sure?")]), _c('p', {
    staticClass: "text-left text-gray-500"
  }, [_vm._v("You want Update ")])]), _c('div', {
    staticClass: "flex justify-center"
  }, [_c('button', {
    staticClass: "bg-blue-500 text-white p-1 px-2 rounded-lg mr-1 text-sm",
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "bg-white border text-gray-500 p-1 px-2 rounded-lg ml-1 text-sm",
    on: {
      "click": _vm.confirmUpdateSingle
    }
  }, [_vm._v("Yes")])])]), _c('t-modal', {
    ref: "modal_premium_ax"
  }, [_c('label', {
    staticClass: "text-base mb-3"
  }, [_vm._v("Update Premium Ax")]), _c('div', {
    staticClass: "flex-col w-full"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('label', {
    staticClass: "block text-sm font-medium text-gray-700",
    attrs: {
      "for": "premiumAx"
    }
  }, [_vm._v("Premium Ax")]), _c('t-input', {
    staticClass: "mt-1 block w-full",
    attrs: {
      "id": "premiumAx",
      "type": "number",
      "placeholder": "Enter Premium Ax",
      "min": 0,
      "required": ""
    },
    model: {
      value: _vm.form.premiumAx,
      callback: function ($$v) {
        _vm.$set(_vm.form, "premiumAx", $$v);
      },
      expression: "form.premiumAx"
    }
  })], 1), _c('div', {
    staticClass: "mb-2"
  }, [_c('label', {
    staticClass: "block text-sm font-medium text-gray-700",
    attrs: {
      "for": "basePrice"
    }
  }, [_vm._v("Base Price")]), _c('t-input', {
    staticClass: "mt-1 block w-full",
    attrs: {
      "id": "basePrice",
      "type": "number",
      "placeholder": "Enter Base Price",
      "min": 0,
      "required": ""
    },
    model: {
      value: _vm.form.basePricePremium,
      callback: function ($$v) {
        _vm.$set(_vm.form, "basePricePremium", $$v);
      },
      expression: "form.basePricePremium"
    }
  })], 1)]), _c('div', {
    staticClass: "flex-col w-full"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('label', {
    staticClass: "block text-sm font-medium text-gray-700",
    attrs: {
      "for": "vendor"
    }
  }, [_vm._v("Vendor")]), _c('multiselect', {
    staticClass: "mt-1 block w-full",
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Type Vendor",
      "open-direction": "bottom",
      "options": _vm.vendorSearchOptions,
      "searchable": true,
      "loading": _vm.isVendorSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": false,
      "required": ""
    },
    on: {
      "search-change": _vm.vendorFind,
      "input": _vm.getVendorData
    },
    model: {
      value: _vm.form.vendorPremium,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vendorPremium", $$v);
      },
      expression: "form.vendorPremium"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "block text-sm font-medium text-gray-700",
    attrs: {
      "for": "vendorAx"
    }
  }, [_vm._v("Vendor ID AX")]), _c('t-input', {
    staticClass: "mt-1 block w-full",
    attrs: {
      "id": "vendorAx",
      "type": "text",
      "placeholder": "Enter Vendor ax",
      "required": ""
    },
    model: {
      value: _vm.form.vendorAxPremium,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vendorAxPremium", $$v);
      },
      expression: "form.vendorAxPremium"
    }
  })], 1)]), _c('div', {
    staticClass: "flex justify-end mt-4"
  }, [_c('button', {
    staticClass: "bg-blue-700 text-white p-1 px-2 rounded-lg m-1",
    on: {
      "click": function ($event) {
        return _vm.actionUpdatePremium();
      }
    }
  }, [_vm._v("Update")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }