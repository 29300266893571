<template>
  <div class="row offers mt-2">
    <b-col lg="12">
      <div class="card">
        <h2 class="title text-center pt-1 mt-1">INVOICE</h2>
        
        <div class="col-md-12 pt-2">
          <b-alert show>
            Laporan total penjualan dari semua offers (digital, eperpus) dan siap untuk di cairkan. Laporan dikelompokan berdasarkan vendor, offer type & periode.
          </b-alert>
          <div class="form-group row">
            <span class="col-sm-2">Invoice</span>
            <div class="col-sm-6">
              <input
                type="text"
                v-model="query_search"
                class="form-control"
                placeholder="INV-SGL-251-20230430"
                onfocus="this.placeholder = 'type invoice number...'"
                @input="actionGetReportInvoice()"
              />
            </div>
          </div>
        </div>
        <div class="col-md-12 pt-2">
          <div class="form-group row">
            <span class="col-sm-2">Periode</span>
            <div class="col-sm-auto">
              <Calendar v-model="dates" selectionMode="range" view="month" :manualInput="false" dateFormat="mm/yy"/>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group row">
            <span class="col-sm-2">Offer Type</span>
            <div class="col-sm-auto">
              <b-form-select
                id="searchOfferMode"
                class="flex-grow"
                v-model="offerType"
                track-by="value"
                :options="offerTypeOptions"
                @change="setOfferType"
                required
              >
              </b-form-select>
            </div>
          </div>
          <div class="form-group row">
            <span class="col-sm-2">Publisher</span>
            <div class="col-sm-4">
              <multiselect
                v-model="publisher"
                label="name"
                track-by="name"
                :disabled="userData.role_id === 5"
                placeholder="Type Publisher"
                open-direction="bottom"
                :options="publisherSearchOptions"
                :loading="isPublisherSearch"
                :multiple="true"
                :searchable="true"
                :close-on-select="false"
                @search-change="publisherFind"
              >
              </multiselect>
            </div>
            <div class="col-sm-2">
              <button
                class="btn btn-block btn-primary"
                @click="actionGetReportInvoice"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
            <b-spinner
              v-show="
                isLoadingSearchByInvoiceNumber ||
                  isLoadingSearchByDisbursementPeriod
              "
              variant="dark"
              class="ml-2"
              label="Loading .."
            ></b-spinner>
          </div>
        </div>
        <div class="card-body">
          <div class="row justify-content-end pb-1">
            <span>
              <button
                id="refresh-list"
                class="btn btn-sm btn-dark"
                @click="actionGetReportInvoice"
              >
                <em class="fa fa-refresh"></em>
              </button>
            </span>
            <label for="refresh-list" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{ totalRows }}</strong>
          </div>
          <b-table 
            sticky-header="500px"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            responsive="sm"
            class="mt-2"
            hover
          >
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
            </template>

            <template #cell(vendor_name)="row">
              {{ row.item.vendor_name }}
            </template>
            <template #cell(number_invoice)="row">
              {{ row.item.number_invoice }}
            </template>
            <template #cell(disbursement_period)="row">
              {{ formatPeriod(row.item.disbursement_period) }}
            </template>
            <template #cell(disbursement)="row">
              <div v-if="!row.item.disbursement">
                <i> In callculated</i>
              </div>
              <div v-else>
                {{ formatCurrency(row.item.disbursement,"IDR") }}
              </div>
            </template>
            <template #cell(offer_type)="row">
              <div v-if="row.item?.offer_type == 'single'">
                <span class="badge badge-primary p-1">
                  <i class="fa fa-tag"></i> {{ row.item?.offer_type }}
                </span>
              </div>
              <div v-if="row.item?.offer_type == 'bundle'">
                <span class="badge badge-yellow p-1"> 
                  <i class="fa fa-tag"></i> {{ row.item?.offer_type }}
              </span>
              </div>
              <div v-if="row.item?.offer_type == 'subscription'">
                <span class="badge badge-danger">
                  <i class="fa fa-tag"></i> {{ row.item?.offer_type }}
                </span>
              </div>
              <div v-if="row.item?.offer_type == 'buffet'">
                <span class="badge badge-purple p-1">
                  <i class="fa fa-diamond"></i> 
                  {{ row.item?.offer_type }}
                </span>
              </div>
            </template>
            <template #cell(action_download)="row">
              <div v-if="
               userData.role_id === 5 
               && isPeriodAllowed(row.item.disbursement_period)  
               || userData.role_id === 1 
               || userData.role_id === 14">
                <b-button
              :id="`invoice-download-${row.item.number_invoice}`"
              :variant="row.item.is_active ? 'outline-primary' : 'outline-danger'"
              @click="
                downloadInvoice({
                  offer_type: row.item.offer_type,
                  vendor_id: row.item.vendor_id,
                  period: row.item.disbursement_period,
                  index: row.index,
                  number_invoice: row.item.number_invoice
                })
              "
                v-if="row.item.alloweddownload"
                :disabled="
                  buttonLoading[row.index] || 
                  buttonDownloaded[row.index] || 
                  !row.item.is_active || 
                  !isPeriodAllowed(row.item.disbursement_period)
                "
              >
                <div v-if="row.item.is_active && !buttonLoading[row.index] && !buttonDownloaded[row.index]">
                  <i class="fa fa-download"></i> Download
                </div>
                <div v-else-if="!row.item.is_active && row.item.alloweddownload">
                  <i class="fa fa-ban"></i> Unavailable
                </div>
                <div v-else-if="buttonLoading[row.index]">
                  <i class="fa fa-spinner fa-spin"></i> 
                  <span class="d-inline-block ml-2" tabindex="0" data-toggle="tooltip" :title="tooltipText">
                    Loading ..
                  </span>
                </div>
                <div v-else-if="buttonDownloaded[row.index]">
                  <i class="fa fa-check"></i>
                  <span class="d-inline-block ml-2" tabindex="0" data-toggle="tooltip" :title="tooltipText">
                    Processed
                  </span>
                </div>
                <div v-else></div>
              </b-button>
            </div>

             
              <div
                v-if="!row.item.alloweddownload">
                <i class="fa fa-warning">  </i>
                Vendor Id Issue
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1 mt-2 pt-4">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
        <div>
          <blockquote class="blockquote text-center">
            <p class="mb-0 font-sm">
              This Page for preview only, please download to get Detail Invoice 
              detail invoice will send to your email after download
            </p>
            <footer class="blockquote-footer">
              Gramedia Digital <cite title="Source Title">Finance Report </cite>
            </footer>
          </blockquote>
        </div>
      </div>
    </b-col>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { ReportInvoiceSinglePath } from '../../router/report';
import constant from '../../store/constant';
export default {
  name: 'manage-report-invoice',
  data() {
    return {
      dates: null,
      ReportInvoiceSinglePath,
      tooltipText:'',
      userData : JSON.parse(localStorage.getItem('session_auth')),
      indexRow: -1,
      currentPage: 1,
      buttonLoading: [],
      buttonDownloaded: [],
      perPage: 50,
      query_search: '',
      isLoadingSearchByInvoiceNumber: false,
      isLoadingSearchByDisbursementPeriod: false,
      searchMode: '1',
      searchModeOptions: [
        { value: '1', text: 'Search By Number Invoice' },
        { value: '2', text: 'Search By Disbursement Period' },
      ],
      range: {
        start: moment()
          .startOf('month')
          .subtract(1, 'M')
          .format('YYYY-MM-DD'),
        end: moment()
          .subtract(1, 'M')
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      offerType: 0,
      offerTypeOptions: [
        { value: 0, text: 'All Offer' },
        { value: constant.OFFER_TYPE.SINGLE, text: 'Single' },
        { value: constant.OFFER_TYPE.BUNDLE, text: 'Bundle' },
        { value: constant.OFFER_TYPE.SUBSCRIPTIONS, text: 'Subscriptions' },
        { value: constant.OFFER_TYPE.BUFFET, text: 'Buffet' },
      ],
      debounce: null,
      alertCheckRevenue: null,
      isLoadingCheckRevenue: false,
      publisher: [],
      publisherSearchOptions: [],
      isPublisherSearch: false,
      
      fields: [
        { key: 'No', label: 'No' },
        {
          key: 'vendor_name',
          label: 'Vendor',
        },
        {
          key: 'number_invoice',
          label: 'Number Invoice',
        },
        {
          key: 'disbursement_period',
          label: 'Period',
        },
        {
          key: 'offer_type',
          label: 'Offer Type',
        },
        {
          key: 'disbursement',
          label: 'Estimated Revenue'
        },
        { key: 'action_download', label: 'Download' },
        // { key: "action_button", label: "Action" },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.report.isLoading,
      items: (state) => state.report.items,
      isError: (state) => state.report.isError,
      totalRows: (state) => state.report.totalRows,
      errorMessage: (state) => state.report.errorMessage,
      successMessage: (state) => state.report.successMessage,
      selfVendorId: (state) => state.report.selfVendorId,
      isDownloadLoading: (state) => state.report.isDownloadLoading,
    }),
  },
  watch: {
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
    },
    currentPage: function() {
      this.actionGetReportInvoice();
    },

    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Invoice | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  created() {
    this.publisherFind();
      this.fields = this.fields.filter(field => field.key !== 'disbursement');
  },
  methods: {
    ...mapActions('report', [
      'fetchReportInvoice',
      'searchReportInvoice',
      'downloadReportInvoiceIntoEmailPublisher',
      'getReportInvoiceTotalRevenue',
    ]),
    ...mapActions('vendors', ['searchVendors']),
    actionGetReportInvoice() {
      this.buttonDownloaded= []
      if (this.query_search) {
        let payload = {
          q: this.query_search,
          page: this.currentPage,
          limit: this.perPage,
        };
        this.fetchReportInvoice(payload);
        this.setQuery(payload);
      } else {
        let payload = {
          page: this.currentPage,
          limit: this.perPage,
        };
        if(this.dates){
          payload.startRange = moment(this.dates[0]).endOf('month').format('YYYY-MM-DD');
          payload.endRange = this.dates[1] ? moment(this.dates[1]).endOf('month').format('YYYY-MM-DD') : moment(this.dates[0]).endOf('month').format('YYYY-MM-DD');
        }
        if (this.offerType) payload.offer = this.offerType;
        if (this.publisher.length) {
          payload.publisher = this.publisher
            ? this.publisher.map((val) => val?.id)
            : '';
        }
        this.fetchReportInvoice(payload);
        this.setQuery(payload);
      }
    },
    isPeriodAllowed(period) {
      const [year, month] = period.split("-").map(Number);

      if (year > 2024 || (year === 2024 && month > 10 && this.userData.role_id === 5)) {
        return false;
      }
      return true;
    },
    setQuery(q = {}) {
      this.$router.push({ query: q }).catch(() => {});
    },
    setQuerySearch(query) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.query_search = query;
      }, 1000);
    },
    setSearchMode(mode) {
      this.searchMode = mode;
    },
    formatDate(tgl) {
      if (tgl) {
        return moment(tgl).format('DD MMM YY hh:mm');
      }
      return '-';
    },
    formatPeriod(tgl) {
      if (tgl) {
        return moment(tgl).format('D MMMM YYYY');
      }
      return '-';
    },
    asyncFindByInvoiceNumber() {
      this.isLoadingSearchByInvoiceNumber = true;
      clearTimeout(this.debounce);
      this.publisher = [];
      this.offerType = 0;
      this.range.start = moment().format('DD MMM YY');
      this.range.end = moment().format('DD MMM YY');
      this.debounce = setTimeout(() => {
        const query = this.query_search;
        const modeQuery = 1;
        if (!query || !modeQuery) {
          this.actionGetReportInvoice();
          this.isLoadingSearchByInvoiceNumber = false;
          return;
        }
        this.searchReportInvoice({
          q: query,
          page: this.currentPage,
          limit: this.perPage,
          mode: modeQuery,
        })
          .then((response) => {
            this.items = response.data.data.rows;
            this.isLoadingSearchByInvoiceNumber = false;
          })
          .catch(() => {
            this.isLoadingSearchByInvoiceNumber = false;
          });
      }, 1000);
    },
    asyncFindByDisbursementPeriod() {
      this.isLoadingSearchByDisbursementPeriod = true;
      this.query_search = null;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        const modeQuery = 2;
        if (!modeQuery) {
          this.actionGetReportInvoice();
          this.isLoadingSearchByDisbursementPeriod = false;
          return;
        }
        const payload = {
          page: this.currentPage,
          limit: this.perPage,
          mode: modeQuery,
          startRange: this.range.start
            ? new Date(this.range.start).toISOString()
            : null,
          endRange: this.range.end
            ? new Date(this.range.end).toISOString()
            : null,
          offer: this.offerType,
          publisher: this.publisher.map((item) => item.id),
        };
        this.searchReportInvoice(payload)
          .then((response) => {
            this.items = response.data.data.rows;
            this.isLoadingSearchByDisbursementPeriod = false;
          })
          .catch(() => {
            this.isLoadingSearchByDisbursementPeriod = false;
          });
      }, 1000);
    },
    asyncClearFind() {
      this.query_search = '';
      this.searchMode = '1';
      this.isLoadingSearchByInvoiceNumber = false;
      this.isLoadingSearchByDisbursementPeriod = false;
      this.range = {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      };
      this.offerType = 0;
      this.publisher = [];
      this.publisherSearchOptions = [];
      this.actionGetReportInvoice();
    },
    showModal(numberInvoice) {
      this.alertCheckRevenue = null;
      this.isLoadingCheckRevenue = false;
      this.$refs[`${numberInvoice}`].show();
    },
    downloadInvoice(payload) {
      this.$set(this.buttonLoading, payload.index, true);
      this.tooltipText = this.successMessage || 'Invoice berhasil diproses, silakan cek email Anda dalam 5 menit ke depan.';
      setTimeout(() => {
        this.$set(this.buttonLoading, payload.index, false);
        this.$set(this.buttonDownloaded, payload.index, true);
        this.downloadReportInvoiceIntoEmailPublisher(payload);
      }, 8000);
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    setOfferType(type) {
      this.offerType = type;
    },
   async publisherFind(query) {
    if(this.userData.role_id === 5) {
      try {
      const response = await this.searchVendors({ email: this.userData.email });
        this.publisherSearchOptions = response.data.data.rows;
        this.publisher =this.publisherSearchOptions
      } catch (error) {
        console.error('Error fetching publisher data:', error);
      }
    }
   
      if (!query) return;
      this.isPublisherSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchVendors(payload)
          .then((response) => {
            this.publisherSearchOptions = response.data.data.rows;
            this.isPublisherSearch = false;
          })
          .catch(() => {
            this.isPublisherSearch = false;
          });
      }, 600);
    },

    clearInvoiceNumber() {
      this.query_search = null;
    },
    formatCurrency(value,currency) {
      if (value) {
        let formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else if (currency == 'SGD') {
          formatter = new Intl.NumberFormat('en-SG', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return 'In callculated';
    }
  },
};
</script>
<style scoped>
.badge-purple {
  background-color: #ff06dc;
}
.badge-yellow {
  background-color: #ffec2e;
  color: black;
}
</style>
