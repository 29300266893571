<template>
    <div>
        <div class="bg-white p-4 text-gray-500  my-4 rounded-lg shadow-sm">
            <span class="py-3 text-gray-500">
                Newspapers<i class="fa fa-angle-right ml-2" aria-hidden="true"></i> Bulk Edit Printed Price
                <h5 class="mt-1 text-xl font-semibold text-gray-700">Bulk Edit Printed Price</h5>
            </span>
        </div>
        <div class="mt-4 bg-white rounded-lg px-4 py-3 w-full flex justify-start items-center shadow-sm">
            <div class="mr-2 flex justify-start items-center">
                <div
                :class="{
                        'rounded-full border-custom border-blue-500 w-7 h-7 p-1 justify-center text-sm flex font-semibold text-blue-500': !nextSection,
                        'rounded-full w-7 h-7 border p-1 justify-center text-sm flex': nextSection
                    }"
                >
                    1
                </div>
                <span 
                :class="{
                        'ml-3 text-blue-500 font-semibold': !nextSection,
                        'ml-3 text-gray-500 font-semibold': nextSection
                    }"
                >Bulk Update</span>
            </div>
            <div class="mr-2">
                <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m7 16 4-4-4-4m6 8 4-4-4-4" />
                </svg>

            </div>
            <div class="mr-3 flex justify-start items-center">
                <div 
                :class="{
                        'rounded-full border-custom border-blue-500 w-7 h-7 p-1 justify-center text-sm flex font-semibold text-blue-500': nextSection,
                        'rounded-full w-7 h-7 border p-1 justify-center text-sm flex': !nextSection
                    }"
                >2</div>
                <span 
                :class="{
                        'ml-3 text-blue-500 font-semibold': nextSection,
                        'ml-3 text-gray-500 font-semibold': !nextSection
                    }"
                >Preview</span>
            </div>
            <span></span>
        </div>
        <div  v-if="!nextSection" class="bg-white my-3 py-3 rounded-lg px-4 shadow-sm">
            <div class="w-full flex justify-between">
                <div class="w-1/2 pr-10">
                    <div class="block pr-2">
                        <label class="font-semibold text-gray-600">Brand Name</label>
                        <multiselect v-model="form.brand" label="name" track-by="name" placeholder="Enter Brand"
                            class="w-full mr-4" open-direction="bottom" required="required"
                            :options="brandSearchOptions" :searchable="true" :loading="isBrandSearch"
                            :close-on-select="true" :options-limit="10" :multiple="true" :allow-empty="false"
                            @search-change="brandFind" @select="brandSelected">
                        </multiselect>
                    </div>
                    <div class="block">
                        <label class="font-semibold text-gray-600 mt-3">Release Date</label>
                        <div class="grid gap-4 grid-cols-2 mt-0 mb-3">
                            <div class="block w-full">
                                <DatetimeCustom :timeDate="false"  :label="'Start Date'" v-model="form.release_date">
                                </DatetimeCustom>
                            </div>
                            <div class="block w-full">
                                <DatetimeCustom :timeDate="false"  :label="'End Date'" v-model="form.release_schedule">
                                </DatetimeCustom>
                            </div>
                        </div>
                    </div>
                    <div class="grid gap-4 grid-cols-2 mt-3 mb-3">
                        <div class="mt-0">
                            <label class="font-semibold text-gray-600 mb-3">Printed Price Currency (Optional)</label>
                            <t-select id="currency" v-model="form.currency" :options="currencys" required></t-select>
                        </div>
                        <div class="mt-0">
                            <label class="font-semibold mb-3 text-gray-600">Printed Price (Optional)</label>
                            <input id="price" v-model="form.price" type="number" class="w-full bg-gray-50 rounded-lg border"
                            step="any" placeholder="99000 (no sparator)" required />
                        </div>
                    </div>
                    <div class="flex w-full justify-start mb-3">
          <button :disabled="this.form.brand.length === 0" :class="this.form.brand.length === 0 ? 'opacity-50' :''" @click="handleNextClick" class="bg-blue-700 text-white py-2 px-3 rounded-lg mt-3 mr-2">
            Next
          </button>
          <button class="bg-white border py-2 px-3 rounded-lg mt-3 mr-2" @click="handleCancel">Cancel</button>
        </div>
                </div>
            </div>
        </div>

        <div v-if="nextSection" class="bg-white my-3 py-3 rounded-lg px-4 shadow-sm">
                <tableNewspaperBulky
                :selectOnDataTable="this.form.brand"
                :start_date="this.form.release_date"
                :end_date="this.form.release_schedule"
                :price="this.form.price"
                :currency="this.form.currency"
                :nextSection="this.nextSection"
                @update:nextSection="nextSection = $event"
                />
        </div>
    </div>

</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import DatetimeCustom from "../../../components/form/Datetime.vue"
const PRINTED_PRICE = Object.values(constant.PRINTED_PRICE_ITEM);
import constant from '../../../store/constant';
import { EventBus } from '../../../event-bus'
import tableNewspaperBulky from './tableNewspaperBulky.vue';
export default {
    name: "editbulkynewspaper",
    components: {
        DatetimeCustom,
        tableNewspaperBulky
    },
    data() {
        return {
            form: {
                brand: [],
                price:"",
                currency: 'IDR',
                display_name: "",
                release_date: moment().toString(),
                release_schedule: moment().toString(),
            },
            isBrandSearch: false,
            nextSection: false,
            currencys: [...PRINTED_PRICE],
            brandSearchOptions: [],
            show: true,
            debounce: null,
        };
    },
    computed: {
        ...mapState({
            isError: (state) => state.newspaper.isError,
            isLoading: (state) => state.newspaper.isLoading,
            errorMessage: (state) => state.newspaper.errorMessage,
            successMessage: (state) => state.newspaper.successMessage,
        }),
    },
    watch: {
        errorMessage: function () {
            if (!this.errorMessage) return;
            EventBus.$emit('show-toast', {
                message: this.errorMessage,
                type: 'success',
            });
        },
        successMessage: function () {
            if (!this.successMessage) return;
            EventBus.$emit('show-toast', {
                message: this.successMessage,
                type: 'success',
            });
           // this.$router.push('/newspaper');
        },
    },
    created() {
        this.brandFind('');
    },
    methods: {
        ...mapActions("brands", ["searchBrands"]),
        brandFind(query) {
            this.isBrandSearch = true;
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                let payload = {
                    name: query,
                    type: constant.ITEM_TYPES.NEWSPAPER.toLowerCase(),
                    limit: 10,
                };
                this.searchBrands(payload).then((response) => {
                    this.brandSearchOptions = response.data.data.rows;
                    this.isBrandSearch = false;
                }).catch(() => {
                    this.isBrandSearch = false;
                });
            }, 600);
        },
        async brandSelected() {
            if (!this.form.release_date || !this.form.brand) return;
            const dateNow = moment(this.form.release_date).format('DD MMM YYYY');
            this.form.display_name = `${this.form.brand?.name} / ${dateNow.toUpperCase()}`;
        },
        handleNextClick() {
            this.nextSection = !this.nextSection
        },
        handleCancel() {
            this.$router.push('/newspaper');
        }
    }
}
</script>

<style scoped>
.border-custom {
    border: 1px solid #3b82f6;
}
</style>