<template>
  <div class="rounded-lg shadow-none">
    <div class="d-flex align-items-end justify-content-start mb-4 px-4" style="gap: 10px"
      v-if="isSelectAll || selectedItem.length">
      <button class="bg-red-500 text-white rounded-lg p-2 px-2" v-if="roleID !== 5" variant="outline-danger"
        @click="onSubmitUnPublishSelected" :disabled="isLoadingUnPublish">
        <b-spinner small v-if="isLoadingUnPublish"></b-spinner>
        <em class="fa fa-eye-slash" v-else></em>
        UnPublished
      </button>
      <label for="" v-if="roleID !== 5">{{ selectedItem.length }} selected item</label>
    </div>
    <t-table :headers="fields" :data="books" @row-selected="onRowSelected" ref="tableList" class="shadow-none">
      <template v-slot:thead="props">
        <thead>
          <tr>
            <th>
              <t-checkbox v-if="roleID !== 5" class="ml-4" v-model="isSelectAll" @input="onSelectAllRows">
              </t-checkbox>
            </th>
            <th :class="props.thClass" class="text-center">
              No
            </th>

            <th :class="props.thClass">
              Name
            </th>
            <th :class="props.thClass">
              Content Type
            </th>
            <th :class="props.thClass">
              Is Active
            </th>
            <th :class="props.thClass">
              Item Status
            </th>
            <th :class="props.thClass">
              Printed Price
            </th>
            <th :class="props.thClass" class="text-center">
              Page Count
            </th>
            <th :class="props.thClass" class="text-center">
              Size
            </th>
            <th :class="props.thClass" class="cursor-pointer">
              <div class="flex items-center cursor-pointer" @click="sortBy('release_date')">
                RELEASE DATE
                <span href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg>
                </span>
              </div>
            </th>
            <th :class="props.thClass" class="cursor-pointer">
              <div class="flex items-center cursor-pointer" @click="sortBy('release_schedule')">
                RELEASE SCHEDULE
                <span href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg>
                </span>
              </div>
            </th>
            <th :class="props.thClass">
              VENDOR
            </th>
            <th :class="props.thClass">
              Action
            </th>
          </tr>
        </thead>
      </template>
      <template v-if="isLoading" v-slot:tbody="props">
        <tbody :class="props.tbodyClass">
          <tr :class="[props.trClass, 'text-center']">
            <td :class="props.tdClass" colspan="15">
              <div role="status" class="flex justify-center items-center h-72">
                <svg aria-hidden="true"
                  class="w-8 h-8 text-gray-200 text-center animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
                </svg>
                <span class="sr-only text-center items-center">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-else-if="!books.length" v-slot:tbody="props">
        <tbody :class="props.tbodyClass">
          <tr :class="[props.trClass, 'text-center']">
            <td :class="props.tdClass" colspan="15">
              <div class="flex-col items-center justify-center flex-nowrap flex h-96">
                <svg width="35" height="35" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M36.014 19.1452L29.5936 25.5656L36.014 31.986C36.524 32.5141 36.8063 33.2214 36.7999 33.9555C36.7935 34.6897 36.499 35.3919 35.9799 35.9111C35.4608 36.4302 34.7585 36.7247 34.0243 36.7311C33.2902 36.7375 32.5829 36.4552 32.0548 35.9452L25.6344 29.5248L19.214 35.9452C18.9557 36.2126 18.6467 36.4259 18.3051 36.5727C17.9635 36.7194 17.5961 36.7967 17.2243 36.7999C16.8525 36.8031 16.4838 36.7323 16.1397 36.5915C15.7956 36.4507 15.483 36.2428 15.2201 35.9799C14.9572 35.717 14.7493 35.4044 14.6085 35.0603C14.4677 34.7162 14.3969 34.3475 14.4001 33.9757C14.4033 33.6039 14.4806 33.2365 14.6273 32.8949C14.7741 32.5532 14.9874 32.2443 15.2548 31.986L21.6752 25.5656L15.2548 19.1452C14.7448 18.6171 14.4625 17.9098 14.4689 17.1757C14.4753 16.4415 14.7698 15.7392 15.2889 15.2201C15.8081 14.701 16.5103 14.4065 17.2445 14.4001C17.9786 14.3937 18.6859 14.6759 19.214 15.186L25.6344 21.6064L32.0548 15.186C32.5829 14.6759 33.2902 14.3937 34.0243 14.4001C34.7585 14.4065 35.4608 14.701 35.9799 15.2201C36.499 15.7392 36.7935 16.4415 36.7999 17.1757C36.8063 17.9098 36.524 18.6171 36.014 19.1452Z"
                    fill="#4B5563" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.3775 46.8856C15.5875 49.6986 20.5371 51.2 25.6003 51.2C32.3876 51.1924 38.8947 48.4928 43.694 43.6935C48.4934 38.8942 51.193 32.3872 51.2006 25.6C51.2006 20.5368 49.6992 15.5873 46.8862 11.3774C44.0732 7.16751 40.075 3.8863 35.3971 1.94869C30.7193 0.011092 25.5719 -0.495873 20.6059 0.491908C15.64 1.47969 11.0784 3.91785 7.49817 7.49807C3.9179 11.0783 1.47971 15.6398 0.491914 20.6057C-0.495879 25.5716 0.0110922 30.7189 1.94872 35.3967C3.88634 40.0745 7.1676 44.0726 11.3775 46.8856ZM14.9332 9.63579C18.0907 7.52607 21.8029 6.40001 25.6003 6.40001C30.691 6.40509 35.5717 8.42957 39.1713 12.0292C42.7709 15.6288 44.7955 20.5094 44.8005 25.6C44.8005 29.3974 43.6745 33.1095 41.5647 36.2669C39.455 39.4244 36.4563 41.8853 32.9479 43.3385C29.4395 44.7917 25.579 45.1719 21.8545 44.4311C18.1301 43.6902 14.7089 41.8616 12.0237 39.1764C9.3385 36.4913 7.50986 33.0702 6.76901 29.3457C6.02817 25.6213 6.4084 21.7608 7.86162 18.2525C9.31484 14.7441 11.7758 11.7455 14.9332 9.63579Z"
                    fill="#4B5563" />
                  <path
                    d="M58.5382 63.0624C59.1382 63.6626 59.952 63.9998 60.8006 64C61.4335 63.9999 62.052 63.8121 62.5781 63.4605C63.1043 63.1089 63.5143 62.6091 63.7565 62.0245C63.9986 61.4399 64.062 60.7965 63.9385 60.1759C63.8151 59.5552 63.5105 58.9851 63.0631 58.5376L50.2629 45.7376C49.6594 45.1547 48.8511 44.8322 48.012 44.8394C47.173 44.8467 46.3704 45.1833 45.7771 45.7766C45.1837 46.3699 44.8472 47.1725 44.8399 48.0115C44.8326 48.8506 45.1552 49.6589 45.7381 50.2624L58.5382 63.0624Z"
                    fill="#4B5563" />
                </svg>
                <h4 class="text-base text-gray-600 mb-0">No results found</h4>
                <p class="text-sm text-gray-500">Change your keyword or filter</p>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template slot="row" slot-scope="props">
        <tr  :class="isRowSelected(props.row.id) ? 'bg-gray-400' : 'bg-white'"
          class="border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3">
          <td :class="props.tdClass">
            <i :class="isRowSelected(props.row.id) ? 'fa-solid fa-check text-left ml-3' : ''"></i>
          </td>
          <td :class="props.tdClass"  @click="onRowSelected(props.row.id)">
            {{ (currentPage - 1) * perPage + props.rowIndex + 1 }}
          </td>
          <td :class="props.tdClass" class="max-w-xs" @click="onRowSelected(props.row.id)">
            <router-link :to="{ name: DetailItems.name, params: { id: props.row.id } }"
              class="text-center text-blue-700 ">
              {{ props.row.name }}
            </router-link>

            <button size="sm" variant="outline" @click.stop="copyTextToClipboard(props.row.id)" class="relative">
              <span class="flex items-center relative top-1 text-sm pr-5">
                <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd"
                    d="M7 9v6a4 4 0 0 0 4 4h4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h1v2Z" clip-rule="evenodd" />
                  <path fill-rule="evenodd"
                    d="M13 3.054V7H9.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 13 3.054ZM15 3v4a2 2 0 0 1-2 2H9v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-3Z"
                    clip-rule="evenodd" />
                </svg>
                {{ props.row.id }}
              </span>


            </button>
          </td>
          <td :class="props.tdClass" class="uppercase w-10"  @click="onRowSelected(props.row.id)">
            {{ props.row.content_type }}
          </td>
          <td :class="props.tdClass"  @click="onRowSelected(props.row.id)">
            <span
              :class="[{ 'bg-green-100 text-green-800': props.row.is_active, 'bg-gray-200 text-gray-800': !props.row.is_active }, 'text-left rounded-lg text-xs p-1 px-2 ml-2']">
              {{ formatBoolean(props.row.is_active) }}

            </span>
          </td>
          <td :class="props.tdClass" class="w-36"  @click="onRowSelected(props.row.id)">
            <span v-if="props.row.item_status == itemStatus.Unpublished"
              class="bg-red-100 rounded-lg text-xs text-red-800 p-1">
              {{ props.row.item_status }}
            </span>
            <span v-else-if="props.row.item_status == itemStatus.Published"
              class="bg-blue-100 p-1 rounded-lg text-xs text-blue-800">
              {{ props.row.item_status }}
            </span>
            <span v-else-if="props.row.item_status == itemStatus.Uploaded"
              class="bg-yellow-100 rounded-lg text-xs text-yellow-800 p-1">
              {{ props.row.item_status }}
            </span>
            <span v-else-if="props.row.item_status == itemStatus.UnpublishedMcgrawhill"
              class="bg-gray-100 rounded-lg text-xs text-gray-800 p-1">
              {{ props.row.item_status }}
            </span>
            <span v-else class="bg-green-100 p-1 rounded-lg text-xs text-green-800">
              {{ props.row.item_status }}
            </span>
          </td>
          <td :class="props.tdClass" class="uppercase text-center"  @click="onRowSelected(props.row.id)">
            {{
                formatPrice(props.row.printed_price, props.row.printed_currency_code)
          }}
          </td>
          <td :class="props.tdClass" class="text-center"  @click="onRowSelected(props.row.id)">
            {{ props.row.page_count }}
          </td>
          <td :class="props.tdClass" class="text-center w-28"   @click="onRowSelected(props.row.id)">
            {{ convertMegaBite(props.row.file_size) }}
          </td>
          <td :class="props.tdClass"  @click="onRowSelected(props.row.id)">
            {{ formatterDate(props.row.release_date) }}
          </td>
          <td :class="props.tdClass"  @click="onRowSelected(props.row.id)">
            {{ formatterDate(props.row.release_schedule) }}
          </td>
          <td :class="props.tdClass"  @click="onRowSelected(props.row.id)">
            {{ props.row.vendor }}
          </td>

          <td :class="props.tdClass" class="text-right pr-3 cursor-pointer">
            <t-dropdown>
              <div slot="trigger" slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown
              }">
                <button id="user-menu" :class="{ ' text-gray-500 ': isShown }" aria-label="User menu"
                  aria-haspopup="true" @focus.stop="focusHandler" @blur.stop="blurHandler"
                  @keydown.stop="keydownHandler" @click.stop="mousedownHandler"
                  class="cursor-pointer focus:outline-none">
                  <i class="fa-solid fa-ellipsis"></i>
                </button>
              </div>

              <div slot-scope="{ hide, blurHandler }">
                <button
                  class="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide"  @blur="blurHandler">

                  <div v-if="roleID !== 5" 
                  @click="actionUpdateStatus(props.row, 'publish', status.Published)"
                  class="flex items-center"
                    >
                    <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-width="2"
                        d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                      <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                    <span class="flex items-center ml-2">
                      Published
                    </span>
                  </div>

                </button>
                <button
                  class="block text-left  w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">
                  <div v-if="roleID !== 5" 
                    class="flex items-center" 
                    variant="danger" 
                    @click="actionUpdateStatus(props.row, 'unpublish', status.Unpublished)">
                    
                    <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                    <span class="flex items-center ml-2">
                      UnPublished
                    </span>

                  </div>
                </button>
                <button
                  class="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">
                  <div variant="default" @click="actionEdit(props.row)" class="flex items-center">
                    <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                    </svg>
                    <span class="flex items-center ml-2">
                      Edit Items
                    </span>
                  </div>
                </button>
                <button
                  class="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">
                  <div v-if="props.row.item_type != item_types_audio">
                    <div variant="black" @click="
        actionUpdateStatus(
          props.row,
          'mcgrawhill content',
          status.UnpublishedMcgrawhill,
        )
        " v-if="permBtnMcGraw()">
                      mcgrawhill content
                    </div>
                  </div>

                </button>

                <button v-if="props.row.item_type == 'book'"
                  class="block text-left  w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">
                  <div v-if="!ownVendor.id" class="flex justify-self-start">
                    <div>
                      <i class="fa-solid fa-paper-plane"></i>
                    </div>
                    <div class="ml-3" @click="actionSendAx(props.row)">
                      Register to AX
                    </div>

                  </div>
                </button>
                <button v-if="props.row.item_type == 'book'"
                  class="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">

                  <div v-if="!ownVendor.id" class="flex justify-start">
                    <div>
                      <i class="fa-solid fa-paper-plane"></i>
                    </div>
                    <div class="ml-3" variant="default" @click="actionSendAxPremium(props.row)">
                      Register to AX (PREMIUM)
                    </div>

                  </div>
                </button>

              </div>
            </t-dropdown>
          </td>
        </tr>
      </template>
    </t-table>

    <t-modal ref="confirmationModal">
      <div class="text-sm flex-col text-center">
        <label class="text-center font-semibold">Are You Sure?</label>
        <p class="text-left text-gray-500">This action is quite dangerous, do you still want to continue?</p>
      </div>
      <div class="flex justify-center">
        <button class="bg-blue-500 text-white p-1 px-2 rounded-lg mr-1 text-sm" @click="cancel">Cancel</button>
        <button class="bg-white border text-gray-500 p-1 px-2 rounded-lg ml-1 text-sm"
          @click="confirmBulkUnPublish">Yes</button>
      </div>
    </t-modal>

    <t-modal ref="modalUpdateStatus">
      <div class="text-sm flex-col">
        <label class="text-left font-semibold"> Are you sure want to {{ this.titleModalUpdateStatus }} ?</label>
      </div>
      <div>
        <p class="text-left text-gray-500">{{ nameModalUpdateStatus }} {{ this.statusModalUpdate }} </p>
      </div>
      <div class="flex justify-center">
        <button class="bg-blue-500 text-white p-1 px-2 rounded-lg mr-1 text-sm" @click="cancel">Cancel</button>
        <button class="bg-white border text-gray-500 p-1 px-2 rounded-lg ml-1 text-sm"
          @click="confirmUpdateStatus">Yes</button>
      </div>
    </t-modal>
  </div>
</template>

<script>
import API from '../../plugins/http';
import moment from 'moment';
import constant from '../../store/constant';
import {
  BookEditPath,
  DetailItems,
  MagazineEditPath,
  NewspaperEditPath,
  AudioEditPath,
  AudioEditChapterPath,
} from '../../router/content';
import { mapActions, mapGetters, mapState } from 'vuex';
import { EventBus } from '../../../src/event-bus';
const { ROLES } = constant;
const McGrawHill = [1001]; // McGraw-Hill Education

export default {
  name: 'Books',
  props: {
    books: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    reloadPage: {
      type: Function,
      required: false,
      default() { },
    },
  },
  data() {
    return {
      showModal: false,
      roleID: JSON.parse(localStorage.getItem("session_auth")).role_id,
      Form: {
        id: null,
        id_ax: null,
      },
      sortOrder: 'desc',
      confirmed: false,
      statusModalUpdate: '',
      nameModalUpdateStatus: '',
      titleModalUpdateStatus: '',
      dataItemModalUpateStatus: '',
      item_types_audio: 'audio book',
      fields: [
        {
          key: 'no',
          label: 'No',
          tdClass: 'text-uppercase',
        },
        {
          key: 'title',
          label: 'Name',
          tdClass: 'text-uppercase',

        },
        {
          key: 'content_type',
          tdClass: 'text-uppercase',
        },
        {
          key: 'is_active',
          label: 'Is Active',
          formatter: (value) => {
            return value ? 'Yes' : 'No';
          },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'item_status',
          label: 'Item Status',
          thClass: 'text-capitalize',
          tdClass: 'text-capitalize',
          tdStyle: { width: '250px' },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'printed_price',
          label: 'Printed Price',
          thClass: 'text-right',
          tdStyle: { width: '300px' },
        },
        {
          key: 'page_count',
          label: 'Page Count',
        },
        {
          key: 'size',
          label: 'Size',
          thClass: 'text-right',
        },
        {
          key: 'release_date',
          label: 'Release Date',
          formatter: (value) => {
            return moment(value).format('DD MMM YYYY');
          },
        },
        {
          key: 'release_schedule',
          label: 'Release schedule',
          formatter: (value) => {
            return moment(value).format('DD MMM YYYY');
          },
        },
        { key: 'vendor_name', label: 'Vendor' },
        { key: 'status', label: 'Action' },
      ],
      DetailItems,
      status: {
        Published: constant.ITEM_STATUS.Published,
        Unpublished: constant.ITEM_STATUS.Unpublished,
        UnpublishedMcgrawhill: constant.ITEM_STATUS.UnpublishedMcgrawhill,
      },
      itemStatus: constant.ITEM_STATUS,
      isSelectAll: false,
      isLoadingUnPublish: false,
      selectedItem: [],
    };
  },
  watch: {
    books() {
      this.isSelectAll = false;
      this.selectedItem = []
    },
  },
  computed: {
    ...mapGetters('auth', { auth: 'auth' }),
    ...mapState({
      ownVendor: (state) => state.vendors.item,
    }),
  },
  created() {
   // this.fetchOwnVendors();
  },
  methods: {
    ...mapActions('vendors', ['']),
    ...mapActions('items', ['bulkUnPublish']),
    formatPrice(value, currency) {
      if (!value) {
        return '-'; 
      }
      if (value) {
        const validCurrency = currency || 'IDR'; 

        try {
          const formatter = new Intl.NumberFormat(validCurrency === 'USD' ? 'en-US' : 'en-ID', {
            style: 'currency',
            currency: validCurrency,
          });
          return formatter.format(value);
        } catch (error) {
          console.error("Invalid currency code:", validCurrency);
          return value;
        }
      }
      return '0';
    },
    convertMegaBite(size) {
      if (size) {
        return (size / 1000 ** 2).toFixed(1) + ' MB';
      }
      return '0';
    },
    actionSend() {
      let payload = {
        id_ax: this.Form.id_ax,
        id: this.Form.id,
      };
      API.post('ofs-catalog', payload)
        .then((response) => {
          console.log('ini res', response);
          this.$swal({
            icon: 'success',
            toast: true,
            position: 'top-end',
            title: 'Success send ofs',
            timer: 3000,
            text: this.message,
          });
        })
        .catch((error) => {
          console.log('ini error', error);
          this.$swal({
            icon: 'error',
            toast: true,
            position: 'top-end',
            title: 'Failed send ofs',
            timer: 3000,
            text: this.message,
          });
        });
    },
    actionSendOfs(item) {
      this.showModal = true;
      this.Form.id = item.id;
    },
    mapUrl(item) {
      const itemType = constant.ITEM_TYPES;
      if (item.item_type == itemType.BOOK.toLowerCase()) {
        return BookEditPath.name;
      }
      if (item.item_type == itemType.MAGAZINE.toLowerCase()) {
        return MagazineEditPath.name;
      }
      if (item.item_type == itemType.NEWSPAPER.toLowerCase()) {
        return NewspaperEditPath.name;
      }
      if (item.item_type == itemType.AUDIOBOOK.toLowerCase()) {
        return AudioEditPath.name;
      }
      if (item.item_type == 'Chapter') {
        return AudioEditChapterPath.name;
      }
    },
    actionEdit(item) {
      this.$router.push({
        name: this.mapUrl(item),
        params: {
          id: item.id,
        },
      });
    },
    actionSendAx(item) {
      let payload = {
        id: item.id,
      };
      API.post('register-ax', payload)
        .then(() => {
          EventBus.$emit('show-toast', {
            message: 'Success send ax',
            type: 'success',
          });
        })
        .catch((error) => {
          console.log(error)
          EventBus.$emit('show-toast', {
            message: 'Failed send ax',
            type: 'error',
          });
        });
    },

    actionSendAxPremium(item) {
      let payload = {
        id: item.id,
      };
      API.post('register-ax/premium', payload)
        .then(() => {
          EventBus.$emit('show-toast', {
            message: 'Success send ax premium item.',
            type: 'success',
          });
        })
        .catch((err) => {
          if (err.response.status == 404) {
            EventBus.$emit('show-toast', {
              message: 'Book not Found',
              type: 'error',
            });
          } else {
            EventBus.$emit('show-toast', {
              message: err.response.data.message,
              type: 'error',
            });
          }
        });
    },
    async sendRequestByStatus({ item_id, status, item_type, title }) {
      return API.put(`items/status/${item_id}`, { status, item_type })
        .then((response) => {
          EventBus.$emit('show-toast', {
            message: `Success ${title}`,
            type: 'success',
          });
          setTimeout(() => {
            this.reloadPage();
          }, 3000)
          this.$refs.modalUpdateStatus.hide();

          return response;
        })
        .catch((error) => {
          EventBus.$emit('show-toast', {
            message: `Failed unpublish`,
            type: 'error',
          });
          return error;
        });
    },
    actionUpdateStatus(item, title, status) {
      this.dataItemModalUpateStatus = item
      this.$refs.modalUpdateStatus.show();
      this.titleModalUpdateStatus = title;
      this.statusModalUpdate = status;
      this.nameModalUpdateStatus = item.name
    },
    async confirmUpdateStatus() {
      await this.sendRequestByStatus({
        item_id: this.dataItemModalUpateStatus.id,
        status: this.statusModalUpdate,
        item_type: this.dataItemModalUpateStatus.item_type,
        title: this.titleModalUpdateStatus,
      })
    },
    permBtnMcGraw() {
      switch (this.auth?.role_id) {
        case ROLES.SUPER_ADMIN:
          return true;
        case ROLES.INTERNAL_ADMIN:
          return true;
        case ROLES.INTERNAL_DATA_ENTRY:
          return true;
        default:
          if (McGrawHill.includes(this.ownVendor?.id)) return true;
          return false;
      }
    },
    findIndexRowMcGrawHill(items = []) {
      const statusMcGrawhill = this.status.UnpublishedMcgrawhill;
      return items
        .map((item, idx) => {
          if (item.item_status == statusMcGrawhill) {
            return idx;
          }
        })
        .filter((val) => ![undefined, null].includes(val));
    },
    unselectRowByIndex(indexItem = []) {
      indexItem.forEach((index) => {
        this.$refs.tableList.unselectRow(index);
      });
    },

    onSelectAllRows() {
      if (!this.books) return;
      if (this.isSelectAll) {
        this.selectedItem = this.books.map(book => book.id);
      } else {
        this.selectedItem = [];
      }
    },


    isRowSelected(rowId) {
      return this.selectedItem && this.selectedItem.includes(rowId);
    },
    formatBoolean(value) {
      return value ? 'Yes' : 'No';
    },
    formatterDate: (value) => {
      return moment(value).format('DD MMM YYYY');
    },
    onRowSelected(rowId) {
      const index = this.selectedItem.indexOf(rowId);
      if (index > -1) {
        this.selectedItem && this.selectedItem.includes(rowId);
        this.selectedItem.splice(index, 1);
      } else {
        this.selectedItem.push(rowId);
      }
    },

    async confirmBulkUnPublish() {
      this.$refs.confirmationModal.show();
      this.confirm()
      this.cancel()
    },
    confirm() {
      this.confirmed = true
      const items_id = this.selectedItem;
      this.isLoadingUnPublish = true;
      this.bulkUnPublish({items_id})
        .then((response) => {
          this.$refs.confirmationModal.hide();
          if (response.status == 201) {
            EventBus.$emit('show-toast', {
              message: 'Success Unpublished',
              type: 'success',
            });
          } else {
            EventBus.$emit('show-toast', {
              message: 'Success Unpublished',
              type: 'error',
            });
          }
        })
        .catch(() => {
          const message =
            'Opps .. Something is wrong,please contact your administrator';
          this.$swal('Process Failed', message, 'error');
        })
        .finally(() => {
          this.isLoadingUnPublish = false;
          this.reloadPage();
        });

    },

    cancel() {
      this.$refs.confirmationModal.hide();
      this.$refs.modalUpdateStatus.hide();
      this.selectedItem = []
    },
    
    sortBy(criteria) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      const queryParam = {
        criteria: criteria,
        order: this.sortOrder
      }
      this.$emit('sort-by', queryParam);
    },
    async onSubmitUnPublishSelected() {
      this.$refs.confirmationModal.show();
    },
  },
};
</script>
