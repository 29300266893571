<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <form  @submit="onSubmit" id="form-edit">
    <div class="bg-white w-full rounded-lg p-2 mt-4 mb-3 px-4">
    
    <label>
      <router-link class="text-gray-700 font-semibold" :to="{name: MagazinePath.name}">
        Magazines <i class="fa-solid fa-chevron-right text-xs mr-2 text-gray-500"></i> 
      </router-link>
      <span class="text-gray-500">Edit</span>
    </label>
    <h4 class="text-xl font-semibold">Edit Magazine</h4>
  </div>
  <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-4">

    <h4 class="text-xl font-bold mb-4">Edit Magazine</h4>
    <div class="flex pb-2  justify-between w-full">
      <div class="w-1/2 pr-10">
        <div class="block ">
          <label class="font-semibold text-gray-600">Display Name (Item’s Title)</label>
          <input id="display" v-model="form.name" class="w-full bg-gray-50 rounded-lg border " type="text"
          placeholder="Enter Display Name" required />
        </div>
        <div class="grid gap-4 grid-cols-2 pt-3">
          <div>
            <label class="font-semibold text-gray-600 block">Edition Code</label>
            <input id="display" v-model="form.edition_code" class="w-full bg-gray-50 rounded-lg border " type="text"
            placeholder="Edition Code" required />
          </div>
          <div>
            <label class="font-semibold text-gray-600 block">Slug</label>
            <input id="display" v-model="form.slug" class="w-full bg-gray-50 rounded-lg border " type="text"
            placeholder="Slug" required />
          </div>
          <div>
            <label class="font-semibold text-gray-600 block">Item Status</label>
            <t-select id="display"   v-model="form.item_status"
            :options="itemStatusOptions" class="w-full bg-gray-50 rounded-lg border " 
            placeholder="Item Status" required ></t-select>
          </div>
          <div>
            <label class="font-semibold text-gray-600 block">Issue Number</label>
            <input id="display"   
            class="w-full bg-gray-50 rounded-lg border " 
            type="text"
            v-model="form.issue_number"
            :disabled="userData.role_id === 5" 
            placeholder="Enter Issue Number"
            required />
          </div>
        </div>
      </div>
      <div class="w-1/2 pl-10">
        <div>
          <label class="font-semibold text-gray-600 block">Languages</label>
          <multiselect
                placeholder="Enter languages"
                open-direction="bottom"
                v-model="form.languages"
                label="name"
                track-by="name"
                :custom-label="nameWithISO"
                :options="languagesSearchOptions"
                :searchable="true"
                :close-on-select="true"
                :multiple="true"
                required
              >
              </multiselect>
        </div>
        <div class="mt-3">
          <label class="font-semibold text-gray-600 block">Countries</label>
          <multiselect
                  placeholder="Enter Countries"
                  open-direction="bottom"
                  v-model="form.countries"
                  label="name"
                  track-by="name"
                  :custom-label="nameWithISO"
                  :options="countriesSearchOptions"
                  :loading="isCountriesSearch"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="true"
                >
                </multiselect>
        </div>
        <div class="mt-3">
          <label class="font-semibold text-gray-600 block">Categories</label>
          <multiselect
                  v-model="form.categories"
                  label="name"
                  track-by="name"
                  placeholder="Enter Categories"
                  open-direction="bottom"
                  :options="categoriesSearchOptions"
                  :loading="isCategoriesSearch"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="true"
                  @search-change="categoriesFind"
                >
                </multiselect>
        </div>
      </div>
    </div>
    <div class="border-t border-b pb-4 flex-col w-full justify-start pt-4 mt-4">
      
      <h4 class="text-xl font-bold mt-2 mb-4 block">Upload Asset</h4>
      <div class="flex justify-start items-center w-1/2 pr-10">
          <div>
        <input class="border w-full rounded-lg" type="file"  ref="fileInput" @change="handleFileChange" placeholder="No file chosen" />
        <span class="text-gray-500 text-sm mt-2 pb-0 block">Max Size 150MB, File Size: {{(fileSizeFormat(this.fileSizeUpload ?? 0))}}, Current File Size: {{fileSizeFormat(this.form.current_size ?? 0)}}</span>
     
          </div>
      <div class="">
        <button
              pill
              size="sm"
              variant="primary"
              @click="onUpload"
              :disabled="isLoading"
              class="bg-black text-white p-2.5 rounded-lg ml-2 mt-0"
              v-if="form.file_content"
            >
            <i v-if="!isLoading" class="fa fa-upload"></i>
              <span v-if="isLoading">Uploading...</span>
              <span v-else>Upload</span>
          </button>
      </div>
      </div>
      <h4 class="text-xl font-bold capitalize block border-t w-full mt-4 pt-3">Detail</h4>
    <div class="justify-start flex">
      <div class="w-1/2 pr-10">
        <DatetimeCustom 
        :label="'Release Date (GMT ' + timezoneString() + ')'" 
        v-model="form.release_date"
        >
        </DatetimeCustom>
        <DatetimeCustom class="mt-3" :label="'Release Schedule (GMT ' + timezoneString() + ')'"
          v-model="form.schedule_date"></DatetimeCustom>
        <div class="grid gap-4 grid-cols-2 mt-3">
          <div>
            <div class="mt-0">
              <label class="font-semibold text-gray-600 mb-3">Printed Price Currency</label>
              <t-select id="currency"   
              v-model="form.currency"
              :options="currencys" required></t-select>
            </div>
            <div class="mt-3">
              <label class="font-semibold text-gray-600 mb-3">Content Type</label>
              <t-select class="bg-gray-50" id="content_type" v-model="form.content" :options="contents"
                required></t-select>
            </div>
            <div class="mt-3">
              <label class="font-semibold mb-3 text-gray-600">Distribution Countries</label>
              <t-select id="distribution" v-model="form.distribution" :options="distributionSearchOptions" disabled
                required></t-select>
            </div>
          </div>
          <div>
            <div class="mt-0">
              <label class="font-semibold mb-3 text-gray-600">Printed Price</label>
              <input id="price" v-model="form.price" type="number" class="w-full bg-gray-50 rounded-lg border"
                step="any" placeholder="99000 (no sparator)" required />
            </div>
            <div class="mt-3">

              <label class="font-semibold  mb-3 text-gray-600">Parental Control</label>
              <t-select class="bg-gray-50" id="parentals" v-model="form.parental" :options="parentals"
                required></t-select>
            </div>
            <div class="mt-3">
              <label class="font-semibold mb-3 text-gray-600">Reading Direction</label>
              <t-select id="readings" v-model="form.reading" :options="readings" required></t-select>
            </div>
          </div>

        </div>
        <div class="flex justify-start">
          <div class="mr-3">
            <t-checkbox v-model="form.is_active" :value="true" :unchecked-value="false" />
            <label class="text-gray-600 font-semibold mt-3 ml-2">Active</label>
          </div>
          <div   v-if="!isProfileVendor">
            <t-checkbox v-model="form.is_internal_content" :value="true" :unchecked-value="false" />
            <label class="text-gray-600 font-semibold mt-3 ml-2">Internal Content</label>
          </div>

        </div>
        <div class="flex justify-start mt-2">
          <button class="bg-blue-700 p-2 px-2
           text-white rounded-lg " 
           type="submit" 
           form="form-edit"
          :hidden="isLoading"
          
          >
            Save
          </button>
          <button
            class="bg-white border-blue-700  text-blue-700 border-l border-t border-b border-r rounded-lg ml-3 px-3"
            type="reset">Reset Form</button>
          <button class="bg-white p-2 px-3 text-gray-800 border rounded-lg ml-3 ">

            <router-link :to="{ name: MagazinePath.name }" class="text-capitalize  text-gray-800 font-normal">
              Cancel</router-link>
          </button>
        </div>
      </div>
      <div class="w-1/2 pl-10">
        <div class="block mt-3">
          <label class="font-semibold mb-3 text-gray-600">Category AX</label>
              <t-select id="distribution" v-model="form.category_ax" :options="CategoryAxOptions" disabled
                required></t-select>
        </div>
        <div class="block mt-3">
          <label class="font-semibold mb-3 text-gray-600">Description</label>
          <t-textarea id="description" v-model="form.description" placeholder="Enter something..." rows="5">
          </t-textarea>
        </div>
      </div>
    </div>
    </div>

  </div>
    </form> 
  </b-overlay>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { MagazinePath,DetailItems } from "../../../router/content";
import constant from "../../../store/constant";
import DatetimeCustom from "../../../components/form/Datetime.vue"
import {EventBus} from '../../../event-bus'
const { ITEM_TYPES } = constant;
delete constant.CONTENT_TYPE.AUDIO;
const CONTENT_TYPE = Object.values(constant.CONTENT_TYPE);
const READING_DIRECTION = Object.entries(constant.READING_DIRECTION).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const PARENTAL_CONTROL = Object.entries(constant.PARENTAL_LEVEL_TYPE).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const PRINTED_PRICE = Object.values(constant.PRINTED_PRICE_ITEM);
const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map(item => { return { text: item[0], value: item[1] } })

export default {
  name: "editMagazine",
  components: {
    DatetimeCustom
  },
  data() {
    return {
      MagazinePath,
      userData : JSON.parse(localStorage.getItem('session_auth')),
      basePathCover: constant.URL_STATIC.EBOOK_COVER,
      form: {
        name: "",
        issue_number: "",
        edition_code: "",
        slug: "",
        release_date: "",
        schedule_date: "",
        price: "",
        description: "",
        category_ax: [],
        languages: [],
        countries: [],
        categories: [],
        item_status: [],
        currency: null,
        content: null,
        parental: null,
        distribution: null,
        reading: null,
        file_content: null,
        is_active: false,
        is_internal_content: false,
      },
      fileSizeUpload: 0,
      currencys: [
        { text: "Select Currency", value: null }, ...PRINTED_PRICE],
      contents: [{ text: "Select Content", value: null }, ...CONTENT_TYPE],
      parentals: [{ text: "Select Parental Type", value: null }, ...PARENTAL_CONTROL],
      readings: [
        { text: "Select Direction", value: null }, ...READING_DIRECTION
      ],
      distributionSearchOptions: [
        { text: "Select Distributin Countries", value: null }
      ],
      CategoryAxOptions : [
      { text: "SUB ART", value: null }
      ],
      itemStatusOptions: ITEM_STATUS,
      show: true,
      item_id: null,
      axSearchOptions: [],
      categoriesSearchOptions: [],
      countriesSearchOptions: [],
      languagesSearchOptions: [],
      isAxSearch: false,
      isCategoriesSearch: false,
      isCountriesSearch: false,
      successOnsubmit:false,
      debounce: null,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.magazine.isError,
      isLoading: (state) => state.magazine.isLoading,
      errorMessage: (state) => state.magazine.errorMessage,
      successMessage: (state) => state.magazine.successMessage,
      item: (state) => state.magazine.item,
      refresh: (state) => state.magazine.refresh,
      isProfileVendor: (state) => state.profile?.vendor_id,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
    //  this.messageAlert('error', this.errorMessage, 5000);
      EventBus.$emit('show-toast', {
            message:this.errorMessage,
            type: 'error',
          });
    },
    successMessage: function () {
      if (!this.successMessage) return;
      // this.fetchMagazineById({ id });
      EventBus.$emit('show-toast', {
            message:this.successMessage,
            type: 'success',
          });
          if (this.successOnsubmit === true) {
          this.$router.push({ name: DetailItems.name, params: { id: this.item_id } });
      } 
    
    },
    item: function () {
      if (!Object.keys(this.item).length) return;
      this.setEditMagazine();
    },
    refresh: function () {
      if (!this.refresh) return;
      this.fetchMagazineById({ id: this.item_id });
    }
  },
  created() {
    this.setCountriesOptions();
    this.languagesSearchOptions = Object.entries(constant.LANGUAGE_CHOICES).map((item) => {
      return {
        iso: item[0].toUpperCase(),
        name: item[1]
      }
    });
    let id = this.$route.params.id;
    this.item_id = id;
    this.fetchMagazineById({ id });
    this.fetchProfile();
  },
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    ...mapActions('magazine', ['updateMagazine', 'uploadAsset', 'fetchMagazineById']),
    ...mapActions('items', ['searchCategoriesV2', 'getCountries']),
    ...mapActions('ax', ['searchCategoryAx']),
    onSubmit(event) {
      event.preventDefault();
      if (!this.validateForm()) return;

      const data = this.form;
      const payload = {
        item_id: this.item_id,
        name: data.name,
        edition_code: data.edition_code,
        slug: data.slug,
        content_type: data.content.toLowerCase(),
        printed_price: data.price,
        categories_id: data.categories.map(item => item.id),
        release_date: data.release_date,
        release_schedule: data.schedule_date,
        description: data.description,
        printed_currency_code: data.currency,
        issue_number: data.issue_number,
        is_active: data.is_active,
        is_internal_content: data.is_internal_content,
        category_ax_id: data.category_ax.id,
        parentalcontrol_id: data.parental,
        reading_direction: data.reading,
        item_distribution_country_group_id: data.distribution,
        countries: data.countries.map(item => item.iso.toLowerCase()),
        languages: data.languages.map(item => item.iso.toLowerCase()),
        item_status: this.form.item_status,
      }
      this.updateMagazine(payload);
      this.successOnsubmit = true
    },

    validateForm() {
      this.form.edition_code = this.form?.edition_code.trim();
      this.form.slug = this.form?.slug.trim();

      if (this.form?.edition_code?.includes(' ')) {
        this.$swal('Edition Code!!', 'cannot contain spaces', 'warning');
        return false;
      }
      if (this.form?.slug?.includes(' ')) {
        this.$swal('Slug!!', 'cannot contain spaces', 'warning');
        return false;
      }

      return true;
    },
    handleFileChange(event) {
      const file = event.target.files[0];
       this.form.file_content = file;
       this.fileSizeUpload = file.size
    },
    fileSizeFormat(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return (
        parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      );
    },
   async onUpload() {
      this.isDownload = true;
      const payload = {
        id: this.item_id,
        file_content: this.form.file_content,
        item_status: this.form.item_status,
        release_schedule: this.form.schedule_date,
      }; 
      if (!payload.file_content) {
        this.messageAlert('error', 'No File Selected !', 5000);
        return;
      }
     await this.uploadAsset(payload);
    },

    axFind(query) {
      if (!query) return;

      this.isAxSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchCategoryAx(payload).then((response) => {
          this.axSearchOptions = response.data.data.rows;
          this.isAxSearch = false;
        }).catch(() => {
          this.isAxSearch = false;
        });
      }, 600);
    },

    categoriesFind(query) {
      if (!query) return;

      this.isCategoriesSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchCategoriesV2({
          name: query,
          item_type: ITEM_TYPES.MAGAZINE.toLowerCase(),
          is_active: 1,
        })
          .then((response) => {
            this.categoriesSearchOptions = response.data.data.rows;
            this.isCategoriesSearch = false;
          })
          .catch(() => {
            this.isCategoriesSearch = false;
          });
      }, 600);
    },

    setCountriesOptions(setCountries = []) {
      this.isCountriesSearch = true;
      this.getCountries()
        .then((response) => {
          this.countriesSearchOptions = response.data.data.rows;
          this.isCountriesSearch = false;

          this.form.countries = this.countriesSearchOptions
            .filter(
              item => [...setCountries].includes(item.iso.toLowerCase())
            );
        })
        .catch(() => {
          this.isCountriesSearch = false;
        });
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },

    nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
    },

    setEditMagazine() {
      const data = this.item;
      this.form.name = data.name;
      this.form.issue_number = data.issue_number;
      this.form.edition_code = data.edition_code;
      this.form.slug = data.slug;
      this.form.release_date = moment(data.release_date).toString();
      this.form.schedule_date = moment(data.release_schedule).toString();
      this.form.price = data.printed_price;
      this.form.currency = data.printed_currency_code;
      this.form.content = data.content_type.toUpperCase();
      this.form.parental = data.parentalcontrol_id;
      this.form.reading = data.reading_direction;
      this.form.is_internal_content = data.is_internal_content;
      this.form.is_active = data.is_active;
      this.form.current_size = data.file_size;
      this.form.file_content = null;
      this.form.item_status = data.item_status;

      this.form.languages = this.languagesSearchOptions
        .filter(
          item => [...data.languages].includes(item.iso.toLowerCase())
        );
      this.setCountriesOptions(data.countries);

      this.form.category_ax = {
        id: data.category_ax.category_ax_id,
        name: data.category_ax.category_ax,
      }
      this.form.categories = data.categories;
      this.form.description = data.description;

    },
  },
  filters: {
    convertSize(value) {
      const size = parseFloat(value);
      return (size / 1000 ** 2).toFixed(1)
    }
  }
};
</script>

<style lang="css" scoped>
.fix-bot-right {
  position:fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 0 10px white;
}
</style>
